import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/Pagination";
import { updatePageNumber } from "../../../state/reducers/pagination";
import { makeSearchRequest } from "../../../state/reducers/search";
import { fetchPurchasesArchive } from "../../../state/reducers/purchasesArchive";
import { fetchBidsAndRequests } from "../../../state/reducers/bidsAndRequests";
import { fetchShortlist } from "../../../state/reducers/shortlist";
import { fetchInvoices } from "../../../state/reducers/invoices";
import { fetchMessages } from "../../../state/reducers/messages";
import { fetchActivityLogs } from "./../../../state/reducers/activityLogs";
import { fetchClaims } from "../../../state/reducers/claims";
import { fetchVendorClaims } from "../../../state/reducers/claimsVendor";
import { fetchVendorCampaigns } from "../../../state/reducers/campaignManagement";
import { makeSearchRequest as vendorMakeSearchRequest } from "../../../state/reducers/vendorVehicleSearch";

const PaginationWrapper = () => {
  const resultType = useSelector((state) => state.global.results.resultType);
  const getUpdateFunction = (resultType) => {
    switch (resultType) {
      case "search":
        return makeSearchRequest();
      case "purchasesArchive":
        return fetchPurchasesArchive();
      case "bidsAndRequests":
        return fetchBidsAndRequests();
      case "shortlist":
        return fetchShortlist();
      case "invoices":
        return fetchInvoices();
      case "activityLogs":
        return fetchActivityLogs();
      case "messages":
        return fetchMessages();
      case "claims":
        return fetchClaims();
      case "claimsVendor":
        return fetchVendorClaims();
      case "campaignManagement":
        return fetchVendorCampaigns();
      case "vendorVehicleSearch":
        return vendorMakeSearchRequest();
      default:
        return () => {};
    }
  };

  const dispatch = useDispatch();
  const totalPages = useSelector((state) => state[resultType].pageCount || 1);
  const currentPage = useSelector((state) => state.pagination.pageNumber);
  const refreshResults = () => dispatch(getUpdateFunction(resultType));

  return (
    <Pagination
      callback={(value) => {
        dispatch(updatePageNumber(parseInt(value, 10), resultType));
        refreshResults();
      }}
      activePage={currentPage}
      totalPages={totalPages}
    />
  );
};

export default PaginationWrapper;
