import { updateObject } from "../../shared/utility";
import VendorVehicleSearchApi from "../../api/VendorVehicleSearch";
export const SEARCH_REQUEST = "@vendorVehicleSearch/SEARCH_REQUEST";
export const SEARCH_REQUEST_SUCCESS = "@vendorVehicleSearch/SEARCH_REQUEST_SUCCESS";
export const SEARCH_REQUEST_FAILURE = "@vendorVehicleSearch/SEARCH_REQUEST_FAILURE";
export const CLEAR_FILTERS = "@vendorVehicleSearch/CLEAR_FILTERS";
export const SET_FILTERS = "@vendorVehicleSearch/SET_FILTERS";

export const searchRequestSuccess = (data) => {
    return {
        type: SEARCH_REQUEST_SUCCESS,
        payload: data,
    };
};

export const searchRequestFailure = (filtersNotAvailable = false) => {
    return {
        type: SEARCH_REQUEST_FAILURE,
        payload: { filtersNotAvailable: filtersNotAvailable }
    };
};

/**
 * @returns {Function}
 */

export const makeSearchRequest = () => (dispatch, getState) => {
    dispatch({ type: SEARCH_REQUEST });

    const filters = getState().vendorVehicleSearch.filters;
    const toolbar = getState().toolbar;
    const pageNumber = getState().pagination.pageNumber;

    const { pageSize, sort, bucketType } = toolbar;

    // Remove any searchParameters that have a value of blank, null or undefined
    Object.keys(filters || {}).forEach(key => {
        if (filters[key] === '' || filters[key] === null || filters[key] === undefined) {
            delete filters[key];
        }
    });

    const params = {
        filters,
        pageSize,
        sort,
        pageNumber,
    };
    const searchInstance = new VendorVehicleSearchApi({
        params,
        credentials: getState().authentication.credentials,
    });

    searchInstance.call().then(
        (response) => {
            const {
            } = response;
            dispatch(searchRequestSuccess(response));
        },
        (err) => {
            return dispatch(searchRequestFailure());
        }
    );
};

export const clearFilters = () => {
    return {
        type: CLEAR_FILTERS,
    };
};

export const setFilters = (filters) => {
    return {
        type: SET_FILTERS,
        payload: filters,
    };
};

const initialState = {
    isFetching: false,
    error: false,
    results: {},
    filters: {},
    filtersNotAvailable: false,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_REQUEST:
            return updateObject(state, { isFetching: true });
        case SEARCH_REQUEST_SUCCESS:
            return updateObject(state, {
                isFetching: false,
                totalCount: action.payload.totalCount,
                pageSize: action.payload.pageSize,
                pageCount: action.payload.pageCount,
                results: action.payload.vehicles,
                filtersNotAvailable: false,
            });
        case SEARCH_REQUEST_FAILURE:
            return updateObject(state, {
                isFetching: false,
                // TODO: we may not want to clear the results...
                results: {},
                error: true,
                filtersNotAvailable: action.payload.filtersNotAvailable,
            });
        case CLEAR_FILTERS:
            return updateObject(state, {
                filters: {},
            });
        case SET_FILTERS:
            return updateObject(state, {
                filters: action.payload,
            });
        default:
            return state;
    }
};

export default reducer;
