import classnames from "classnames";
import React, { Fragment } from "react";
import { useTable } from "react-table";
import PropTypes from "prop-types";

const Table = ({ className, data, columns, previewIndex, preview }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className={classnames(className)}>
      <table {...getTableProps()} style={{ width: "100%" }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th key={i} {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIdx) => {
            prepareRow(row);
            return (
              <Fragment key={rowIdx}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIdx) => (
                    <td key={cellIdx}>{cell.render("Cell")}</td>
                  ))}
                </tr>
                {rowIdx === previewIndex && preview && (
                  <tr className="preview-row">
                    <td colSpan={columns.length}>
                      <div>{preview}</div>
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  preview: PropTypes.node,
  previewIdx: PropTypes.number,
};

export default Table;
