// Action constants

import { resetPagination } from "./pagination";
import { fetchPurchaseDetails } from "./purchase";
import { fetchPricingDetails } from "./pricing";
import { clearFilters } from "./filter";
import { clearBidData } from "./bid";
import { clearVehicleRegRequest } from "./regSearch";
import { clearInvoiceNumberRequest } from "./invoices";
import { clearToolbarState } from "./toolbar";
import { vendorPaths } from "../../vendor/shared/consts";
import clientSettings from "../../clientappsettings.json";

export const OPEN_MODAL = "@global/OPEN_MODAL";
export const CLOSE_MODAL = "@global/CLOSE_MODAL";
export const REGISTER_NAVIGATION_EVENT = "@global/REGISTER_NAVIGATION_EVENT";
export const TOGGLE_TERMS_AND_CONDITIONS =
  "@global/TOGGLE_TERMS_AND_CONDITIONS";
export const SET_ACTIVE_BID_VEHICLE_ID = "@global/SET_ACTIVE_BID_VEHICLE_ID";
export const CLEAR_ACTIVE_BID_VEHICLE_ID =
  "@global/CLEAR_ACTIVE_BID_VEHICLE_ID";
export const SET_ACTIVE_FILTER = "@global/SET_ACTIVE_FILTER";
export const CLEAR_ACTIVE_FILTER = "@global/CLEAR_ACTIVE_FILTER";
export const SET_SEARCH_PAGE_SCROLL_POSITION_Y =
  "@global/SET_SEARCH_PAGE_SCROLL_POSITION_Y";
export const CLEAR_SEARCH_PAGE_SCROLL_POSITION_Y =
  "@global/CLEAR_SEARCH_PAGE_SCROLL_POSITION_Y";
export const SET_SELECTED_GROUP_BUYER = "@global/SET_SELECTED_GROUP_BUYER";
export const CLEAR_GROUP_BUYER = "@global/CLEAR_GROUP_BUYER";
export const LOG_OUT = "@global/LOG_OUT";
export const LOG_OUT_SUCCESS = "@global/LOG_OUT_SUCCESS";
export const SET_ACTIVE_CLAIM_ID = "@global/SET_ACTIVE_CLAIM_ID";
export const CLEAR_ACTIVE_CLAIM_ID = "@global/CLEAR_ACTIVE_CLAIM_ID";
export const SET_ACTIVE_CAMPAIGN = "@global/SET_ACTIVE_CAMPAIGN";
export const CLEAR_ACTIVE_CAMPAIGN = "@global/CLEAR_ACTIVE_CAMPAIGN";
export const SET_PARENT_ORIGIN = "@global/SET_PARENT_ORIGIN";
export const SET_PARENT_RESULT_TYPE = "@global/SET_PARENT_RESULT_TYPE";
export const SET_NOTIFICATION_DATA = "@global/SET_NOTIFICATION_DATA";
export const CLEAR_NOTIFICATION_DATA = "@global/CLEAR_NOTIFICATION_DATA";

export const setActiveBidVehicleId = (vehicleId) => {
  return { type: SET_ACTIVE_BID_VEHICLE_ID, payload: vehicleId };
};

export const clearActiveBidVehicleId = () => {
  return { type: CLEAR_ACTIVE_BID_VEHICLE_ID };
};

export const setActiveClaimId = (claimId) => {
  return { type: SET_ACTIVE_CLAIM_ID, payload: claimId };
};

export const clearActiveClaimId = () => {
  return { type: CLEAR_ACTIVE_CLAIM_ID };
};

export const setActiveCampaign = (campaignId, campaignName, action) => {
  return {
    type: SET_ACTIVE_CAMPAIGN,
    payload: { campaignId, campaignName, action },
  };
};

export const clearActiveCampaign = () => {
  return { type: CLEAR_ACTIVE_CAMPAIGN };
};

export const setParentOrigin = (origin) => {
  return {
    type: SET_PARENT_ORIGIN,
    payload: origin,
  };
};

export const setParentResultType = (resultType) => {
  return {
    type: SET_PARENT_RESULT_TYPE,
    payload: resultType,
  };
};

// Action creators
export const openModal = (modalId) => {
  return {
    type: OPEN_MODAL,
    payload: modalId,
  };
};

export const closeModal = () => (dispatch, getState) => {
  // Handles both early exit from the bidding flow as well as flow completion
  const hasActiveBid = getState().global.bids.activeBidVehicleId;
  const hasReadTermsAndConditions =
    getState().global.purchases.hasReadTermsAndConditions;
  const hasActiveClaim = getState().global.claims.activeClaimId;
  if (hasActiveBid) {
    dispatch(clearActiveBidVehicleId());
  }
  if (hasReadTermsAndConditions) {
    dispatch(toggleTermsAndConditions());
  }
  if (hasActiveClaim) {
    dispatch(clearActiveClaimId());
  }

  const hasActiveCampaign = getState().global.campaigns.activeCampaignId;
  if (hasActiveCampaign) {
    dispatch(clearActiveCampaign());
  }

  return dispatch({
    type: CLOSE_MODAL,
  });
};

export const closeModalWithNavigation = (path, navigate) => (dispatch) => {
  navigate(path, { refresh: true });
  return dispatch({ type: CLOSE_MODAL });
};

export const toggleTermsAndConditions = () => {
  return {
    type: TOGGLE_TERMS_AND_CONDITIONS,
  };
};

export const registerVendorNavigationEvent =
  (pathname) => (dispatch, getState) => {
    dispatch({ type: REGISTER_NAVIGATION_EVENT, payload: pathname });
    const isModalOpen = getState().global.modals.isOpen;
    const pageNumber = getState().pagination.pageNumber;

    if (isModalOpen) {
      dispatch(closeModal());
    }

    if (pageNumber > 1) {
      dispatch(resetPagination());
    }

    dispatch(setManifestFile());
  };

export const setManifestFile = () => (dispatch, getState) => {
  const { current } = getState().global?.breadcrumbs;
  const themeReference = getState().authentication.meta.theme.value;
  const vendor = getState().authentication.meta.userType;

  let manifestFileName = "manifest.json";

  if (themeReference) {
    switch (themeReference) {
      case "TradeBuyer":
        if (
          (vendor === "Vendor" || vendor === null) &&
          current &&
          current.includes("/vendor/")
        ) {
          manifestFileName = "trade-vendor-manifest.json";
        } else if (current && current.includes("/login")) {
          manifestFileName = "manifest.json";
        } else if (vendor === "Buyer") {
          manifestFileName = "trade-buyer-manifest.json";
        }
        break;
      case "Volkswagen":
        if (current?.includes("/login") || current === null) {
          manifestFileName = "manifest.json";
        } else {
          manifestFileName = "volkswagen-manifest.json";
        }
        break;
      case "Audi":
        if (current?.includes("/login") || current === null) {
          manifestFileName = "manifest.json";
        } else {
          manifestFileName = "audi-manifest.json";
        }
        break;
      case "Seat":
        if (current?.includes("/login") || current === null) {
          manifestFileName = "manifest.json";
        } else {
          manifestFileName = "seat-manifest.json";
        }
        break;
      case "Skoda":
        if (current?.includes("/login") || current === null) {
          manifestFileName = "manifest.json";
        } else {
          manifestFileName = "skoda-manifest.json";
        }
        break;
      case "VolkswagenCommercial":
        if (current?.includes("/login") || current === null) {
          manifestFileName = "manifest.json";
        } else {
          manifestFileName = "volkswagen-commercial-manifest.json";
        }
        break;
      case "Motability":
        manifestFileName = "manifest.json";
        break;
      default:
        manifestFileName = "manifest.json";
    }

    const manifestLink = document.getElementById("manifest-link");

    manifestLink.href = `${clientSettings.SUB_PATH}/${manifestFileName}`;
  }
};

export const registerNavigationEvent = (pathname) => (dispatch, getState) => {
  dispatch({ type: REGISTER_NAVIGATION_EVENT, payload: pathname });

  const isModalOpen = getState().global.modals.isOpen;
  const hasReadTerms = getState().global.purchases.hasReadTermsAndConditions;
  const pageNumber = getState().pagination.pageNumber;
  const { origin, current, parentOrigin } = getState().global.breadcrumbs;
  /**
   *  We clear any stale data on navigation.
   *  However, If we are moving between the home page and vehicle
   *  details page, we should assume we are in 'Search Mode' and
   *  retain filter state.
   */
  if (
    current &&
    (current.includes("/shortlist") ||
      current === "/" ||
      current.includes("/purchases") ||
      current.includes("/bids-and-request"))
  ) {
    dispatch(setParentOrigin(current));
  }

  const IsClearFilter =
    (parentOrigin === "/" && current === "/shortlist") ||
    (parentOrigin === "/shortlist" && current === "/");

  const isSearchMode =
    (origin &&
      (origin.includes("/vehicle/") ||
        origin.includes("/purchase-confirmation/"))) ||
    (current &&
      (current.includes("/vehicle/") ||
        current.includes("/purchase-confirmation/")));

  if (!isSearchMode || IsClearFilter) {
    dispatch(clearFilters(false));
    dispatch(clearToolbarState());
    dispatch(clearSearchPageScrollPositionY());
  }

  dispatch(clearActiveFilter());
  dispatch(clearInvoiceNumberRequest());
  dispatch(clearBidData());
  dispatch(clearVehicleRegRequest());

  if (isModalOpen) {
    dispatch(closeModal());
  }
  if (hasReadTerms) {
    dispatch(toggleTermsAndConditions());
  }
  if (
    (!isSearchMode ||
      IsClearFilter ||
      (current === "/purchases/archive" &&
        parentOrigin !== "/purchases/archive") ||
      (origin?.includes("/purchase-confirmation") &&
        current === parentOrigin &&
        parentOrigin !== "/")) &&
    pageNumber > 1
  ) {
    dispatch(resetPagination());
  }

  dispatch(setManifestFile());
};

export const setActiveFilter = (filterType) => {
  return {
    type: SET_ACTIVE_FILTER,
    payload: filterType,
  };
};

export const clearActiveFilter = () => {
  return {
    type: CLEAR_ACTIVE_FILTER,
  };
};

export const setSearchPageScrollPositionY = (scrollPostion) => {
  return {
    type: SET_SEARCH_PAGE_SCROLL_POSITION_Y,
    payload: scrollPostion,
  };
};

export const clearSearchPageScrollPositionY = () => {
  return {
    type: CLEAR_SEARCH_PAGE_SCROLL_POSITION_Y,
  };
};

export const setGroupBuyer = (option) => (dispatch, getState) => {
  dispatch({
    type: SET_SELECTED_GROUP_BUYER,
    payload: option,
  });
  const vehicleId = getState().purchase.purchaseDetails.vehicleId;
  dispatch(fetchPurchaseDetails(vehicleId, option.value));

  const selectedGroupBuyer = getState().global.purchases.selectedGroupBuyer;
  if (selectedGroupBuyer) {
    return dispatch(
      fetchPricingDetails(vehicleId, null, selectedGroupBuyer.value),
    );
  }
  return dispatch(fetchPricingDetails(vehicleId));
};

export const clearGroupBuyer =
  (withRefresh = false) =>
    (dispatch, getState) => {
      dispatch({
        type: CLEAR_GROUP_BUYER,
      });
      if (withRefresh) {
        const vehicleId = getState().purchase.purchaseDetails.vehicleId;
        dispatch(fetchPurchaseDetails(vehicleId));
        dispatch(fetchPricingDetails(vehicleId));
      }
    };

export const logOut = () => {
  return {
    type: LOG_OUT,
  };
};

export const logOutSuccess = () => {
  return {
    type: LOG_OUT_SUCCESS,
  };
};

export const setNotificationData = (notificationData) => {
  return {
    type: SET_NOTIFICATION_DATA,
    payload: notificationData,
  };
};

export const clearNotificationData = () => {
  return {
    type: CLEAR_NOTIFICATION_DATA,
  };
};

/**
 *
 * @param {string} pathname
 * @returns {string | null}
 */
const getResultType = (pathname) => {
  switch (pathname) {
    case "/":
      return "search";
    case "/invoices":
      return "invoices";
    case "/activity-log":
      return "activityLogs";
    case "/shortlist":
      return "shortlist";
    case "/bids-and-requests":
      return "bidsAndRequests";
    case "/purchases/archive":
      return "purchasesArchive";
    case "/claims":
      return "claims";
    case vendorPaths.CLAIMS:
      return "claimsVendorDashboard";
    case "/messages":
      return "messages";
    case vendorPaths.CLAIMS_IN_PROGRESS:
    case vendorPaths.COMPLETED_CLAIMS:
    case vendorPaths.NEW_CLAIMS:
    case vendorPaths.CALLBACK_REQUESTED:
    case vendorPaths.OFFER_ACTION_REQUIRED:
      return "claimsVendor";
    case vendorPaths.NEW_CAMPAIGNS:
    case vendorPaths.CAMPAIGN_MANAGEMENT:
      return "campaignManagement";
    case vendorPaths.VEHICLE_RECORD:
      return "vendorVehicleRecord"
    case vendorPaths.VEHICLE_SEARCH:
      return "vendorVehicleSearch";
    default:
      return "search";
  }
};

export const initialState = {
  breadcrumbs: {
    current: null,
    origin: null,
    parentOrigin: "/",
  },
  filters: {
    activeFilter: null,
  },
  modals: {
    isOpen: false,
    modalId: null,
  },
  purchases: {
    hasReadTermsAndConditions: false,
    selectedGroupBuyer: null,
  },
  bids: {
    activeBidVehicleId: null,
  },
  results: {
    resultType: null,
    parentResultType: null,
  },
  claims: {
    activeClaimId: null,
  },
  campaigns: {
    activeCampaignId: null,
    activeCampaignName: null,
    action: null,
  },
  searchPageScrollPositionY: null,
  isLoggingOut: false,
  notificationData: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_GROUP_BUYER:
      return {
        ...state,
        purchases: {
          ...state.purchases,
          selectedGroupBuyer: action.payload,
        },
      };
    case CLEAR_GROUP_BUYER:
      return {
        ...state,
        purchases: {
          ...state.purchases,
          selectedGroupBuyer: null,
        },
      };
    case SET_ACTIVE_FILTER:
      return {
        ...state,
        filters: {
          activeFilter: action.payload,
        },
      };
    case CLEAR_ACTIVE_FILTER:
      return {
        ...state,
        filters: {
          activeFilter: null,
        },
      };
    case TOGGLE_TERMS_AND_CONDITIONS:
      return {
        ...state,
        purchases: {
          ...state.purchases,
          hasReadTermsAndConditions: !state.purchases.hasReadTermsAndConditions,
        },
      };
    case SET_ACTIVE_BID_VEHICLE_ID:
      return {
        ...state,
        bids: {
          activeBidVehicleId: action.payload,
        },
      };
    case CLEAR_ACTIVE_BID_VEHICLE_ID:
      return {
        ...state,
        bids: {
          activeBidVehicleId: null,
        },
      };
    case SET_ACTIVE_CLAIM_ID:
      return {
        ...state,
        claims: {
          activeClaimId: action.payload,
        },
      };
    case CLEAR_ACTIVE_CLAIM_ID:
      return {
        ...state,
        claims: {
          activeClaimId: null,
        },
      };
    case REGISTER_NAVIGATION_EVENT:
      return {
        ...state,
        breadcrumbs: {
          current: action.payload,
          origin: state.breadcrumbs.current,
          parentOrigin: state.breadcrumbs.parentOrigin,
        },
        results: {
          ...state.results,
          resultType: getResultType(action.payload),
        },
      };
    case OPEN_MODAL:
      return {
        ...state,
        modals: {
          isOpen: true,
          modalId: action.payload,
        },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modals: {
          ...initialState.modals,
        },
      };
    case SET_SEARCH_PAGE_SCROLL_POSITION_Y:
      return {
        ...state,
        searchPageScrollPositionY: action.payload,
      };
    case CLEAR_SEARCH_PAGE_SCROLL_POSITION_Y:
      return {
        ...state,
        searchPageScrollPositionY: null,
      };
    case LOG_OUT:
      return {
        ...state,
        isLoggingOut: true,
      };
    case LOG_OUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
      };
    case SET_ACTIVE_CAMPAIGN:
      return {
        ...state,
        campaigns: {
          activeCampaignId: action.payload.campaignId,
          activeCampaignName: action.payload.campaignName,
          action: action.payload.action,
        },
      };
    case CLEAR_ACTIVE_CAMPAIGN:
      return {
        ...state,
        campaigns: {
          activeCampaignId: null,
          activeCampaignName: null,
          action: null,
        },
      };
    case SET_PARENT_ORIGIN:
      return {
        ...state,
        breadcrumbs: {
          ...state.breadcrumbs,
          parentOrigin: action.payload,
        },
      };
    case SET_PARENT_RESULT_TYPE:
      return {
        ...state,
        results: {
          ...state.results,
          parentResultType: action.payload,
        },
      };
    case SET_NOTIFICATION_DATA:
      return {
        ...state,
        notificationData: action.payload,
      };
    case CLEAR_NOTIFICATION_DATA:
      return {
        ...state,
        notificationData: null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
