import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import HeaderText from "../../../views/components/HeaderText";
import ModalInsert from "../../../views/components/ModalInsert";
import Panel from "../../../views/components/Panel";
import InputText from "../../../views/components/InputText";
import ParagraphText from "../../../views/components/ParagraphText";
import Datepicker from "../../../views/components/Datepicker";
import dayjs from "dayjs";
import { formatPriceWithCurrencySymbol } from "../../../shared/utility";
import { closeModal } from "../../../state/reducers/global";
import SkeletonRows from "../../../views/components/Skeletons/SkeletonRows";
import BidInputError from "../../../views/components/BidInput/child-components/BidInputError";
import { headers, errors, actions } from "./const";

const VendorVehicleRecordReceivePaymentModal = ({
  className,
  purchaseInformationData,
  purchaseInformationFetching,
  onConfirmHandle,
}) => {
  const dispatch = useDispatch();
  const [paymentReference, setPaymentReference] = useState("");
  const [paymentDate, setPaymentDate] = useState(
    dayjs().format("YYYY-MM-DDTHH:mm")
  );
  const [validPaymentReference, setValidPaymentReference] = useState(true);
  const [validPaymentDate, setValidPaymentDate] = useState(true);

  const handlePaymentReferenceChange = (e) => {
    const value = e.target.value;
    const isValid = value.length <= 20;
    setValidPaymentReference(isValid);
    setPaymentReference(value);
  };

  const handleDateChange = (date) => {
    const finalFormattedDate = dayjs(date).format("YYYY-MM-DDTHH:mm");
    validPaymentDateCheck(finalFormattedDate);
    setPaymentDate(finalFormattedDate);
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const validPaymentDateCheck = (date) => {
    if (!date || !dayjs(date, "YYYY-MM-DDTHH:mm", true).isValid()) {
      setValidPaymentDate(false);
    } else {
      setValidPaymentDate(true);
    }
  };

  const validPaymentData =
    paymentDate &&
    validPaymentDate &&
    paymentReference &&
    validPaymentReference;

  return (
    <Fragment>
      <ModalInsert layout={"flex"}>
        <div className={className}>
          <div className="payment-modal-content">
            <div className="payment-modal-header">
              <HeaderText>{headers.receivePayment}</HeaderText>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                gap: "5rem",
              }}
            >
              <div className="detail-list">
                <div className="row">
                  <ParagraphText>{headers.paymentReference}</ParagraphText>
                  <InputText
                    value={paymentReference || ""}
                    onChange={handlePaymentReferenceChange}
                    maxLength={20}
                    validValue={validPaymentReference}
                  />
                </div>
                <div className="row">
                  <ParagraphText>{headers.paymentDate}</ParagraphText>
                  <Datepicker
                    getDate={handleDateChange}
                    defaultDate={paymentDate || ""}
                    setValidValue={setValidPaymentDate}
                    primary
                  />
                </div>
                {paymentDate && !validPaymentDate && (
                  <BidInputError errorMessage={errors.validDate} />
                )}
              </div>
              {!purchaseInformationFetching && purchaseInformationData ? (
                <div className="detail-list">
                  <div className="detail-list__row">
                    <ParagraphText>{headers.purchasedBy}</ParagraphText>
                    <ParagraphText>
                      {purchaseInformationData.purchaseInfo.buyerName}
                    </ParagraphText>
                  </div>
                  <div className="detail-list__row">
                    <ParagraphText>{headers.invoiceNumber}</ParagraphText>
                    <ParagraphText>
                      {purchaseInformationData.purchaseInfo.invoiceNumber}
                    </ParagraphText>
                  </div>
                  <div className="detail-list__row">
                    <ParagraphText>
                      {headers.totalGrossInvoiceValue}
                    </ParagraphText>
                    <ParagraphText>
                      {formatPriceWithCurrencySymbol(
                        purchaseInformationData.purchaseInfo
                          .totalGrossInvoiceValue
                      )}
                    </ParagraphText>
                  </div>
                </div>
              ) : (
                <div className="detail-list">
                  {[...Array(3)].map((_, i) => (
                    <div key={i} style={{ paddingBottom: "1rem" }}>
                      <SkeletonRows nrOfRows={1} paddingNone />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <Panel
              style={{ height: "100%", borderRadius: "0.5rem" }}
              actions={[
                {
                  content: actions.close,
                  action: onCancel,
                  type: "ghost",
                },
                {
                  content: actions.confirm,
                  type: "primary",
                  action: () => onConfirmHandle(paymentReference, paymentDate),
                  disabled: !validPaymentData,
                },
              ]}
            />
          </div>
        </div>
      </ModalInsert>
    </Fragment>
  );
};

export default VendorVehicleRecordReceivePaymentModal;
