import React from "react";
import { useSelector } from "react-redux";
import * as consts from "./const";
import GenericTransitionModal from "../GenericTransitionModal";

const VendorVehicleRecordGenericSuccessModal = () => {
  const { type, data } = useSelector(
    (state) =>
      state.vendorVehicleRecord.genericActiveState.activeConfirmModalOptions
  );

  const actionMap = {
    "accept-bid": consts.ACCEPT_BID,
    "decline-bid": consts.DECLINE_BID,
    "delete-document": consts.DELETE_DOCUMENT,
    hold: consts.HOLD_VEHICLE,
    "modify-hold": consts.MODIFY_HOLD,
    "release-hold": consts.RELEASE_HOLD,
    "release-workshop": consts.RELEASE_WORKSHOP,
    "copy-driver-location": consts.COPY_DRIVER_LOCATION,
    "copy-customer-location": consts.COPY_CUSTOMER_LOCATION,
    "save-location": consts.SAVE_LOCATION,
    "remove-from-sale": consts.REMOVE_FROM_SALE,
    workshop: consts.ADD_WORKSHOP,
    "event-history": consts.EVENT_HISTORY,
    "cancel-payment": consts.CANCEL_PAYMENT,
    "cancel-return": consts.CANCEL_RETURN,
    "delete-all-images": consts.DELETE_ALL_IMAGES,
    "edit-sales-info": consts.EDIT_SALES_INFO,
    "modify-vehicle-details": consts.MODIFY_VEHICLE_DETAILS,
    "cancel-defleet": consts.CANCEL_DEFLEET,
    "get-generic-colour": consts.GET_GENERIC_COLOUR,
    "receive-payment": consts.RECEIVE_PAYMENT,
    publish: consts.PUBLISH,
    "return-vehicle": consts.RETURN_VEHICLE,
    "include-in-auto-profile": consts.INCLUDE_IN_AUTO_PROFILING,
    "terminate-vehicle":consts.TERMINATE_VEHICLE
  };

  const getActionMap = (action) => actionMap[action] || {};

  const getText = (action) => {
    const text = getActionMap(action) 
    
    return {
      headerText: text?.headerText ?? "Process Complete",
      bodyText: text?.bodyText ?? "The process has been completed.",
    };
  };

  const { headerText, bodyText } = getText(data?.action);

  return headerText ? (
    <div>
      <GenericTransitionModal headerText={headerText} bodyText={bodyText} />
    </div>
  ) : null;
};

export default VendorVehicleRecordGenericSuccessModal;
