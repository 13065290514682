import Api from "./Base";
import clientSettings from "../clientappsettings.json";

class VendorVehicleSearchApi extends Api {
    baseUrl = clientSettings.VENDORS_URL;
    requestUrl = "/Vehicle/VehicleSearch";
    method = "POST";
    headers = {
        Authorization: `Bearer ${this.getCredentials()}`
    };

    formatRequestData(params) {
        let formattedData = {
        };
        if (params) {
            var {
                filters,
                pageNumber,
                pageSize,
                sort,
            } = params;


            formattedData = {
                pageNumber,
                pageSize,
                sort,
                ...filters,
            };
            return formattedData;

        }
        return formattedData;
    }

    formatResponseData(response) {
        return response?.data;
    }
}

export default VendorVehicleSearchApi;
