import styled from "styled-components";
import VendorSiteInformationModal from "./VendorSiteInformationModal";
import breakpoints from "../../../views/theme/breakpoints.js";

const StyledVendorSiteInformationModal = styled(VendorSiteInformationModal)`
    .row {
        display: flex;
        flex-direction: column;

        .buyer-information-div {
            width: 100%;
        }
    }

    @media (min-width: ${breakpoints.LARGE_DESKTOP}){
        .row {
            display: flex;
            flex-direction: row;

            .buyer-information-div {
                width: 50%;
            }
        }
    }

`

export default StyledVendorSiteInformationModal;