import Api from "../Base";
import settings from "../../clientappsettings.json";

class Profiling extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchVehicleRecordProfiles extends Profiling {
    requestUrl = `/Profiling/Profiles/${this.getParams().vehicleId}`;
    formatResponseData(response) {
      return response?.data;
    }
  }

  export class IncludeInOnlineSelling extends Profiling {
    method = "POST";
    requestUrl = `/Profiling/IncludeInOnlineSelling/${this.getParams().vehicleId}`;
    formatResponseData(response) {
      return response?.data;
    }
  }

  export class AllocateToProfile extends Profiling {
    method = "POST";
    requestUrl = `/Profiling/AllocateToProfile`;
    formatResponseData(response) {
      return response?.data;
    }
  }

  export class IncludeInAutoProfiling extends Profiling {
    method = "POST";
    requestUrl = `/Profiling/IncludeInAutoProfiling`;
    formatResponseData(response) {
      return response?.data;
    }
  }

  export class RemoveFromProfile extends Profiling {
    method = "POST";
    requestUrl = `/Profiling/RemoveFromProfile`;
    formatResponseData(response) {
      return response?.data;
    }
  }