import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import VendorVehicleRecordReturnVehicleModal from "../../components/VendorVehicleRecordReturnVehicleModal";
import { setActiveConfirmModalOptions } from "../../../state/reducers/vendor-vehicle/genericActiveState";
import { vehicleMenuAction } from "../../../state/reducers/vendor-vehicle/vehicleDetails";

const VendorVehicleRecordReturnVehicleModalContainer = ({ className }) => {
  const dispatch = useDispatch();
  const vehicleReference = useSelector(
    (state) =>
      state.vendorVehicleRecord.genericActiveState.activeVehicleReference
  );
  const onConfirmHandle = (returnDate) => {
    const type = "vehicle";
    const data = {
      vehicleReference,
      action:  "return-vehicle",
    };
    dispatch(setActiveConfirmModalOptions(type, data));
    dispatch(
      vehicleMenuAction({
        actionType: data.action,
        vehicleId: data.vehicleReference,
        actionData: returnDate,
      })
    );
  };

  return (
    <VendorVehicleRecordReturnVehicleModal
      className={className}
      vehicleReference={vehicleReference}
      onConfirmHandle={onConfirmHandle}
    />
  );
};
export default VendorVehicleRecordReturnVehicleModalContainer;
