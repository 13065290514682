export const buyerInfoColumns = [
    [
        { label: "Buyer Name", key: "buyerName" },
        { label: "Buyer Address", key: "buyerAddress" },
    ],
];

export const buyerDetailsColumns = [
    [
        { label: "Main Contact", key: "mainContact" },
        { label: "Telephone Number", key: "telephoneNumber" },
        { label: "Email Address", key: "buyerEmail"},
        { label: "Dealer Code", key: "dealerCode" },
        { label: "Buyer Status", key: "buyerStatus" },
    ],
];

export const modalTitle = "Buyer Information"