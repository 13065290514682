import React from "react";
import Select from "react-select";
import colours from "../../theme/base/colours";

// Custom function to add an icon to each option dynamically
const getOptionLabel = (option) => (
  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: '100%'}}>
    <span>{option.label}</span>
    {option.colour && (
      <div
        style={{
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          border:`1px solid ${colours.BLACK}`,
          backgroundColor: option.colour,
        }}
      />
    )}
  </div>
);

const SelectDropdownWithIcons = ({
  defaultValue,
  options,
  placeholder,
  onChange,
  isSearchable,
  isFetching,
  width,
  disabled,
  value,
}) => {
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      border: `0.2rem solid ${colours.SECONDARY.shades[20]}`,
      "&:hover": {
        borderColor: colours.SECONDARY.shades[20],
      },
      boxShadow: state.isFocused ? "none" : baseStyles.boxShadow,
      color: state.menuIsOpen ? colours.PRIMARY.base : colours.BLACK,
      minHeight: "5.2rem",
      width: width || "100%",
      cursor: "pointer",
      paddingLeft: "1rem",
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: colours.PRIMARY.base,
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      marginLeft: "2px",
      marginRight: "2px",
      boxSizing: "border-box",
    }),
    dropdownIndicator: (baseStyles, state) => ({
      ...baseStyles,
      color: colours.PRIMARY.base,
      transform: state.selectProps.menuIsOpen ? "rotate(-180deg)" : "rotate(0)",
      transition: "transform 0.2s ease",
      "&:hover": {
        color: colours.PRIMARY.base,
      },
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: colours.WHITE,
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isDisabled
        ? undefined
        : state.isSelected
        ? colours.PRIMARY.base
        : state.isFocused
        ? colours.PRIMARY.shades[10]
        : undefined,
      padding: "1rem",
      color: state.isSelected ? colours.WHITE : colours.BLACK,
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      display: "flex",
      alignItems: "center",
      "&:hover": {
        backgroundColor: colours.PRIMARY.base,
        color: colours.WHITE,
      },
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 9999,
    }),
  };

  return (
    <div>
      <Select
        styles={customStyles}
        defaultValue={defaultValue}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        isSearchable={isSearchable}
        isLoading={isFetching}
        isDisabled={disabled}
        value={value || null}
        getOptionLabel={getOptionLabel} 
      />
    </div>
  );
};

export default SelectDropdownWithIcons;

