import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Icon from "../../../../views/components/Icon";
import { campaignTableHeaders } from "../../Table/consts";
import Table from "../../Table";
import dayjs from "dayjs";
import PaginationWrapper from "../../../../views/containers/Pagination";
import SkeletonNewClaims from "../../../../views/components/Skeletons/SkeletonNewClaims";
import ParagraphText from "../../../../views/components/ParagraphText/ParagraphText";
import { NO_CAMPAIGNS_FOUND } from "../../../shared/consts";
import classnames from "classnames";
import { IconContainer } from "../CampaignForm";
import {
  openModal,
  setActiveCampaign,
} from "../../../../state/reducers/global";

const Campaigns = ({
  className,
  campaigns,
  isFetching,
  isDeletingCampaign,
  isApprovingCampaign,
  isUpdating,
  bucket,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fixedColumns = useMemo(
    () => [
      {
        Header: "",
        accessor: "campaignId",
        Cell: ({ cell: { value } }) => (
          <div
            className="magnifying-glass"
            onClick={() => handleRecordCampaignClick(value)}
          >
            <Icon type="search" />
          </div>
        ),
      },
      {
        Header: campaignTableHeaders.CAMPAIGN_NAME,
        accessor: "campaignName",
      },
      {
        Header: campaignTableHeaders.CAMPAIGN_REFERENCE,
        accessor: "campaignReference",
      },
      {
        Header: campaignTableHeaders.START_DATE,
        accessor: "fromDate",
      },
      {
        Header: campaignTableHeaders.END_DATE,
        accessor: "toDate",
      },
      {
        Header: campaignTableHeaders.SALE_DAYS_FROM,
        accessor: "fromDay",
      },
      {
        Header: campaignTableHeaders.SALE_DAYS_TO,
        accessor: "toDay",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const actionColumn = useMemo(
    () => [
      {
        Header: () => {
          const actionHeader = bucket !== "expired";
          return actionHeader
            ? campaignTableHeaders.ACTIONS
            : campaignTableHeaders.ACTION;
        },
        accessor: "action[0]",
        Cell: (value) => {
          if (!value.value) return null;
          const { campaignId, campaignName } = value.value;
          return actionContent(campaignId, campaignName);
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columns = useMemo(() => {
    return [...fixedColumns, ...actionColumn];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fixedColumns, ...actionColumn]);

  const data = useMemo(
    () =>
      campaigns?.map((campaign) => {
        return {
          campaignId: campaign.campaignId,
          campaignReference: campaign.campaignReference
            ? campaign.campaignReference
            : "-",
          campaignName: campaign.campaignName ? campaign.campaignName : "-",
          fromDate: campaign.fromDate
            ? `${dayjs(campaign.fromDate).format("DD/MM/YYYY")}`
            : "-",
          toDate: campaign.toDate
            ? `${dayjs(campaign.toDate).format("DD/MM/YYYY")}`
            : "-",
          fromDay:
            campaign.fromDay === null || undefined ? "-" : campaign.fromDay,
          toDay: campaign.toDay === null || undefined ? "-" : campaign.toDay,
          action: [
            {
              campaignId: campaign.campaignId,
              campaignName: campaign.campaignName,
            },
          ],
        };
      }),
    [campaigns]
  );

  const handleRecordCampaignClick = (campaignId) =>
    navigate(`/vendor/campaign-management/campaign/${campaignId}`, {
      state: { campaignId: campaignId },
    });

  const actionContent = (campaignId, campaignName) => {
    const showTrashCanIcon = bucket !== "expired";
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconContainer
          className="copy-campaign"
          onClick={() => handleCopyCampaign(campaignId, campaignName)}
        >
          <Icon type="copy" width="24" height="24" strokeWidth="24" />
        </IconContainer>
        {showTrashCanIcon && (
          <IconContainer
            className="delete-campaign"
            onClick={() => handleDeleteAction(campaignId, campaignName)}
          >
            <Icon type="trash" width="24" height="24" />
          </IconContainer>
        )}
      </div>
    );
  };

  const handleDeleteAction = (campaignId, campaignName) => {
    if (bucket === "live") {
      handleCampaignExpiry(campaignId, campaignName);
    } else {
      handleDeleteCampaign(campaignId, campaignName);
    }
  };

  const handleDeleteCampaign = (campaignId, campaignName) => {
    dispatch(setActiveCampaign(campaignId, campaignName, "delete"));
    dispatch(openModal("campaign-modal"));
  };

  const handleCampaignExpiry = (campaignId, campaignName) => {
    dispatch(setActiveCampaign(campaignId, campaignName, "expire"));
    dispatch(openModal("campaign-modal"));
  };

  const handleCopyCampaign = (campaignId, campaignName) => {
    dispatch(setActiveCampaign(campaignId, campaignName, "copy"));
    dispatch(openModal("campaign-modal"));
  };

  const widths = ["4%", "25%", "17%", "13%", "13%", "13%", "13%", "10%"];

  return (
    <div className={classnames(className)}>
      <div className="content-newContainer">
        {!isFetching &&
        !isDeletingCampaign &&
        !isApprovingCampaign &&
        !isUpdating ? (
          campaigns?.length ? (
            <div className="campaigns__container">
              <section className="table">
                <Table columns={columns} data={data} widths={widths} />
              </section>
              <div className="campaigns__pagination">
                <PaginationWrapper />
              </div>
            </div>
          ) : (
            <div className="campaigns__no-records">
              <ParagraphText>{NO_CAMPAIGNS_FOUND}</ParagraphText>
            </div>
          )
        ) : (
          <SkeletonNewClaims noOfRows={7} />
        )}
      </div>
    </div>
  );
};

export default Campaigns;
