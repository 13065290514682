import Api from "../Base";
import settings from "../../clientappsettings.json";
import { getResponseObjectProperties } from "../../shared/utility";

class PurchaseInformation extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchVehicleRecordPurchaseInfo extends PurchaseInformation {
    requestUrl = `/PurchaseInfo/${this.getParams().vehicleId}`;
    formatResponseData(response) {
      const purchaseInfoDetails = [
        "buyerName",
        "dateOfSale",
        "purchasePrice",
        "adminFee",
        "transportFee",
        "totalGrossInvoiceValue",
        "salesAccountName",
        "vatStatus",
        "deliveryMethod",
        "invoiceNumber",
        "disposalChannel",
        "saleCategory",
        "capValuation",
        "capValuationDate",
        "capPct",
        "invoiceId",
        "siteId",
        "siteType",
      ];
  
      const paymentInfoDetails = [
        "paymentRef",
        "paymentStatus",
        "paymentReceivedDate",
        "paymentMethod",
      ];
  
      const otherInfoDetails = [
        "odometer",
        "odometerType",
        "dealerCode",
        "saleCommission",
        "vehicleDamageTotal",
        "auctionRetailer",
        "rechargeTotal",
        "totalRepairCost",
        "saleReferenceCode",
        "claimId",
      ];
  
      const notesDetails = [
        "vendorNotes",
        "salesText",
        "damageText",
      ];
  
      return {
        purchaseInfo: getResponseObjectProperties(response.data, purchaseInfoDetails, true),
        paymentInfo: getResponseObjectProperties(response.data, paymentInfoDetails, true),
        otherInfo: getResponseObjectProperties(response.data, otherInfoDetails, true),
        notesInfo: getResponseObjectProperties(response.data, notesDetails, true),
      };
    }
  }

