import settings from "../clientappsettings";
import get from "lodash.get";
import dayjs from "dayjs";
import { toast, Bounce } from "react-toastify";

// TODO: ultimately move these constants into the clientappsettings.json
const BID_INCREMENT = 50;
const MAX_BID_VALUE = 99999999;

/**
 * @returns {number}
 */
export const getBidIncrement = () => BID_INCREMENT;

export const getMaxBidValue = () => MAX_BID_VALUE;

const SORT_OPTIONS = [
  {
    value: "recentlyAdded",
    label: "Recently Added",
    optionName: "Recently Added",
  },
  {
    value: "oldestAdded",
    label: "Oldest - Newest",
    optionName: "Oldest - Newest",
  },
  {
    value: "priceDesc",
    label: "Price: High to Low",
    optionName: "Price: High to Low",
  },
  {
    value: "priceAsc",
    label: "Price: Low to High",
    optionName: "Price: Low to High",
  },
  {
    value: "mileageDesc",
    label: "Odometer: High to Low",
    optionName: "Odometer: High to Low",
  },
  {
    value: "mileageAsc",
    label: "Odometer: Low to High",
    optionName: "Odometer: Low to High",
  },
];

export const getSearchSortOptions = () => SORT_OPTIONS;

/**
 * Returns a value string for displaying in a select component
 * @param {Object} sortObject
 * @returns {string}
 */
export const getSortDisplayValue = (sortObject = {}) => {
  const { sortType, ascending } = sortObject;
  switch (sortType) {
    case "price":
      return ascending ? "priceAsc" : "priceDesc";
    case "mileage":
      return ascending ? "mileageAsc" : "mileageDesc";
    default:
      return ascending ? "oldestAdded" : "recentlyAdded";
  }
};

const SORT_VALUE_MAP = {
  priceAsc: {
    sortType: "price",
    ascending: true,
  },
  priceDesc: {
    sortType: "price",
  },
  recentlyAdded: {
    sortType: "recentlyAdded",
  },
  oldestAdded: {
    sortType: "recentlyAdded",
    ascending: true,
  },
  mileageAsc: {
    sortType: "mileage",
    ascending: true,
  },
  mileageDesc: {
    sortType: "mileage",
  },
};

const NEWEST_OLDEST_SORT_OPTIONS = [
  {
    value: "NewestToOldest",
    label: "Newest to Oldest",
    optionName: "Newest to Oldest",
  },
  {
    value: "OldestToNewest",
    label: "Oldest to Newest",
    optionName: "Oldest to Newest",
  },
];

export const getNewestOldestSortOptions = () => NEWEST_OLDEST_SORT_OPTIONS;

export const getSortOptions = (resultType) => {
  switch (resultType) {
    case "search":
      return getSearchSortOptions();
    case "shortlist":
      return getSearchSortOptions();
    case "invoices":
      return getNewestOldestSortOptions();
    case "activityLogs":
      return getNewestOldestSortOptions();
    case "claims":
      return getNewestOldestSortOptions();
    default:
      return null;
  }
};

/**
 *
 * @param {string} optionValue
 * @returns {*}
 */
export const getSortObject = (optionValue) => SORT_VALUE_MAP[optionValue];

const ORGANISATIONAL_STATUS_LABEL_MAP = {
  LimitedCompany: "Limited Company",
  SoleTrader: "Sole Trader",
};

export const getOrganisationalStatusLabel = (method) =>
  ORGANISATIONAL_STATUS_LABEL_MAP[method] || "";

const VAT_STATUS_LABEL_MAP = {
  Registered: "Registered",
  NotRegistered: "Not Registered",
  ChannelIslands: "Channel Islands",
};

export const getVatStatusLabel = (method) => VAT_STATUS_LABEL_MAP[method] || "";

const PAYMENT_METHOD_LABEL_MAP = {
  VWFSfunding: "VWFS Funding",
  Cheque: "Cheque",
  TelegraphicTransfer: "Bank Transfer",
  DirectDebit: "Direct Debit",
  DebitCard: "Debit Card",
  MFLDirectFunding: "mfldirect Funding",
  FundingProvider: "Funding Provider",
};

export const getPaymentMethodLabel = (method) =>
  PAYMENT_METHOD_LABEL_MAP[method] || "";

export const getPaymentMethodSubtitle = (option) => {
  if (option.bankAccountNo && option.bankSortCode) {
    return `Account: ${option.bankAccountNo}, Sort Code: ${option.bankSortCode}`;
  }
  if (option.subtitle) {
    return option.subtitle;
  }

  return "";
};

/**
 * Combines new values onto original object and returns the updated object
 * @param {Object} oldObject Original object to be updated
 * @param {Object} newValues New values
 * @returns {Object}
 */
export const updateObject = (oldObject, newValues) => {
  return {
    ...oldObject,
    ...newValues,
  };
};

/**
 * Unpacks and parses a base64 encoded JWT of type: 'header.body'
 * @param {string} token a base64 encoded JWT
 * @returns {Object}
 */
export const parseToken = (token = "") => {
  const tokenBody = token && token.split(".")[1];
  if (tokenBody) {
    try {
      return JSON.parse(window.atob(tokenBody));
    } catch (err) {
      // TODO: we might want to log this error...
    }
  }
  return null;
};

/**
 * Check that a JWT has not expired
 * @param {*} token - a base64 encoded JWT
 * @returns {Boolean}
 */
export const isTokenValid = (token) => {
  const unpackedToken = parseToken(token);
  if (unpackedToken) {
    const { exp: expiryTime } = unpackedToken;
    const date = new Date();
    /*
    Our expiry set on the server is in UNIX time (precision -> 1s),
    but JS time is in milliseconds
    */
    const timeNow = Math.floor(date.getTime() / 1000);
    if (expiryTime && expiryTime <= timeNow) {
      return false;
    }
    return true;
  }
  return false;
};

/**
 * Check if a string is not undefined, empty or unspecified
 * @param {*} str - a string value
 * @returns {Boolean}
 */
export const hasValueString = (str) => {
  return str && str.toLowerCase() !== "unspecified";
};

/**
 * Get formatted odometer
 * @param {number} odometer - number
 * @param {"M","K","H"} odometerType
 * @param {boolean} isFull
 * @returns {string} formatted odometer
 */
export const getFormattedOdometer = (
  odometer,
  odometerType,
  isFull = false,
) => {
  let formattedOdometerType = getFormattedOdometerType(odometerType);
  if (isFull) {
    formattedOdometerType = getFullFormattedOdometerType(odometerType);
  }
  return `${odometer
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${formattedOdometerType}`;
};

/**
 * Get formatted odometerType
 * @param {"M","K","H"} odometerType
 * @returns {string} formatted odometerType
 */
export const getFormattedOdometerType = (odometerType) => {
  switch (odometerType.toUpperCase()) {
    case "M":
      return "mi";
    case "K":
      return "km";
    case "H":
      return "hrs";
    default:
      return null;
  }
};

/**
 * Get full formatted odometerType
 * @param {"M","K","H"} odometerType
 * @returns {string} formatted odometerType
 */
export const getFullFormattedOdometerType = (odometerType) => {
  switch (odometerType.toUpperCase()) {
    case "M":
      return "miles";
    case "K":
      return "kilometres";
    case "H":
      return "hours";
    default:
      return null;
  }
};

/**
 * Checks if odometerType is valid
 * @param {"M","K","H"} odometerType
 * @returns {Boolean}
 */
export const isOdometerTypeValid = (odometerType) => {
  return (
    odometerType &&
    (odometerType.toUpperCase() === "M" ||
      odometerType.toUpperCase() === "K" ||
      odometerType.toUpperCase() === "H")
  );
};

/**
 * Removes the from string if it starts with toBeRemoved, if not from is returned.
 * @param {string} from
 * @param {string} toBeRemoved
 * @returns {string}
 */
export const removeStringFromStart = (from, toBeRemoved) => {
  if (from && toBeRemoved) {
    return from.startsWith(toBeRemoved)
      ? from.substring(toBeRemoved.length, from.length)
      : from;
  }
  return from;
};

/**
 * TODO: eslint is throwing some warnings around this function, they should be looked into
 *  Checks if a date string is a valid date
 * @param {string} dateString
 */
export const isDateValid = (dateString) => {
  if (
    dateString &&
    !(new Date(dateString) === "Invalid Date") &&
    !isNaN(new Date(dateString))
  ) {
    return true;
  }
  return false;
};

/**
 * Format damage percentage to desired front-end values
 * @param {string} damagePercentage
 */
export const getDamageDisplayValue = (damagePercentage) => {
  return damagePercentage ? `${damagePercentage}%` : "-";
};

/**
 *
 * @param {string} endDate
 */
export const getAuctionEndDate = (endDate) => {
  return isDateValid(endDate)
    ? dayjs(endDate).format("Do MMM HH:mm")
    : "Open ended";
};

/**
 * Format condition enum to desired front-end values
 * @param {string} condition
 */
export const getConditionDisplayValue = (condition) => {
  switch (condition) {
    case "BelowAverage":
      return "Below Ave";
    default:
      return condition;
  }
};

/**
 * Format vat status enum to desired front-end values
 * @param {string} vatStatus
 */
export const getVatStatusDisplayValue = (vatStatus) => {
  switch (vatStatus) {
    case "NonQual":
    case "NonQualified":
      return "Non-Qual";
    default:
      return vatStatus;
  }
};

export const getV5StatusDisplayValue = (str) => {
  switch (str) {
    case "Yes":
      return "Yes";
    case "AppliedFor":
      return "Applied For";
    case "PassedToTrade":
      return "Passed to Trade";
    case "DispatchedToAuction":
      return "Dispatched to Auction";
    case "No":
      return "No";
    default:
      return "-";
  }
};

export const getInfoTagStrokeColour = (iconType, defaultIconColor, theme) => {
  if (defaultIconColor) {
    return theme.COLOURS.PRIMARY.base;
  }
  switch (iconType) {
    case "exclude":
      return theme.COLOURS.RED.base;
    case "include":
      return theme.COLOURS.GREEN.base;
    case "dash":
      return theme.COLOURS.ORANGE.base;
    case "loading":
      return theme.COLOURS.WHITE;
    default:
      return theme.COLOURS.PRIMARY.base;
  }
};

/**
 * Format a base object that can be consumed by the Pricing endpoint
 * @returns {*}
 * @param selectedPurchasingOptions
 */
export const formatPurchaseOptions = (selectedPurchasingOptions) => {
  const { vehicleId, deliveryMethod, selectedGroupSiteId } =
    selectedPurchasingOptions;
  return {
    vehicleId: vehicleId,
    deliveryMethod: deliveryMethod,
    selectedGroupSiteId: selectedGroupSiteId,
  };
};

/**
 * @param {Array} optionList
 * @returns {*}
 */
export const getDefaultPurchaseOption = (optionList) =>
  optionList.find((item) => item.defaultOption);

/**
 *
 * @param {Object} responseObject
 * @param {String[]} fieldList,
 * @param {Boolean} stringDefault
 * @returns {{}}
 */
export const getResponseObjectProperties = (
  responseObject = {},
  fieldList = [],
  stringDefault = false,
) => {
  const defaults = fieldList.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: stringDefault ? "" : 0,
    }),
    {},
  );
  if (responseObject) {
    return Object.keys(responseObject).reduce((acc, curr) => {
      const resultObject = {
        ...defaults,
        ...acc,
      };
      if (fieldList.indexOf(curr) !== -1) {
        // TODO, iron out this exception
        resultObject[curr === "total" ? "gross" : curr] = get(
          responseObject,
          curr,
          stringDefault ? "" : 0,
        );
      }
      return resultObject;
    }, {});
  }
  return defaults;
};

/**
 * Format the data structure for the vehicle payment price breakdown table
 * @param {Object} pricingDetailData
 * @returns {{}}
 */
export const formatPricingDetailData = (pricingDetailData = {}) => {
  const legalFields = ["net", "vat", "vatCode", "vatQualified", "total"];
  const {
    vehicle,
    admin: adminCharge,
    transport: deliveryCost,
    total,
  } = pricingDetailData;
  return {
    vehicle: getResponseObjectProperties(vehicle, legalFields),
    adminCharge: getResponseObjectProperties(adminCharge, legalFields),
    deliveryCost: getResponseObjectProperties(deliveryCost, legalFields),
    total: getResponseObjectProperties(total, legalFields),
  };
};

/**
 * Format the data structure for the vehicle payment summary card
 * @param purchaseDetailData
 * @returns {*}
 */
export const formatPurchaseDetailData = (purchaseDetailData) => {
  /**
   * TODO: the current prop list for the ConfirmOrder component is
   * currently very flat, the props are a little hard to track and
   * should now be grouped
   * @type {string[]}
   */
  const legalFields = [
    "vehicleBlobUrl",
    "make",
    "range",
    "model",
    "derivative",
    "vehicleId",
    "condition",
    "fuelType",
    "v5",
    "mot",
    "motExpiryDate",
    "auctionGrade",
    "mileage",
    "colour",
    "odometer",
    "odometerType",
    "serviceHistory",
    "regNo",
    "dateOfRegistration",
    "transmission",
    "deliveryLocation",
    "collectionLocation",
    "transportCharge",
    "salesTermsText",
    "tbcDeliveryAllowed",
    "damagePercentage",
    "saleType",
    "buyNowPrice",
  ];
  return {
    ...getResponseObjectProperties(purchaseDetailData, legalFields, true),
  };
};

/**
 * Form a vehicle title string from details props
 * @param {Object} details
 * @returns {string}
 */
export const getVehicleTitle = (details = {}) => {
  if (details) {
    const { make, range } = details;
    const hasTitleData = !!(make && range);
    return hasTitleData ? `‘${make} ${range}’` : "";
  }
  return "";
};

/**
 * Format prices sent from the back end. These can come as an integer, or a float.
 * If float, fix to 2 decimal places
 * @param {Number} priceNumber
 * @returns {string}
 */
export const formatPrice = (priceNumber) => {
  if (priceNumber) {
    /**
     * TODO: We are setting a default "0" in the price formatting utility.
     * This will be removed when the state refactoring comes in on PR 409,
     * but in the meantime we need to be defensive around calling `toFixed()`
     */
    const hasFractional =
      typeof priceNumber !== "string" && !Number.isInteger(priceNumber);
    let priceString = priceNumber.toString();
    if (hasFractional) {
      const priceNumberToFixed = priceNumber.toFixed(2);
      priceString = priceNumberToFixed.toString();
    }
    const integerAndFractional = priceString.split(".");
    const integer = integerAndFractional[0].replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ",",
    );
    const fractional = integerAndFractional[1];

    return `${integer}${fractional ? `.${fractional}` : ""}`;
  }
  /**
   * The bidding start price can legitimately be £0, in that case it will fail the check above.
   * Return string "0" as a fallback
   */
  return "0";
};

/**
 * Format prices sent from the back end. These can come as an integer, or a float.
 * Return formatted price alongwith currency symbol 
 * @param {Number} priceNumber
 * @returns {string}
 */
export const formatPriceWithCurrencySymbol = (price, includePence = false) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: includePence ? 2 : 0,
    maximumFractionDigits: includePence ? 2 : 0,
  }).format(price);
}

/**
 * Returns a default vehicle image
 * @returns {string}
 */
export const getDefaultImage = () => {
  return `${settings.IMAGES_API_URL}/VehicleImage/default`;
};

/**
 * Returns a vehicle fault image
 * @returns {string}
 */
export const getFaultImage = (blobId, imageKey) => {
  return `${settings.IMAGES_API_URL}/vehicleimage/fault/${blobId}?key=${imageKey}`;
};

/**
 * @param allowBuyNow Boolean
 * @param allowBid Boolean
 * @param bidStatus string
 * @param purchasingStatus string
 * @param purchased boolean
 * @param comingSoon boolean
 * @param allowSalesClaim boolean
 * @param claimRaised boolean
 * Returns the vehicle actions
 * @returns {string[]}
 */
export const getVehicleActions = (
  allowBuyNow,
  allowBid,
  bidStatus,
  purchasingStatus,
  purchased,
  comingSoon,
  allowSalesClaim,
  claimRaised,
) => {
  let actions = [];

  // Bidding state
  const canPlaceInitialBid =
    allowBid && (bidStatus === "Declined" || bidStatus === "None");
  const canIncreaseBid =
    allowBid && !(bidStatus === "Declined" || bidStatus === "None");
  const isBidAccepted = bidStatus === "Accepted";

  // Buying state
  const isPurchasePending = purchasingStatus === "Request";

  if (canPlaceInitialBid) {
    actions.push("place-bid");
  }
  if (canIncreaseBid) {
    actions.push("increase-bid");
  }

  if (allowBuyNow) {
    actions.push("buy-now");
  }

  if (comingSoon) {
    actions.push("coming-soon");
  }

  // The following statuses are not combinatorial
  if (isBidAccepted) {
    actions = ["sale-agreed"];
  }

  if (isPurchasePending) {
    actions = ["purchase-pending"];
  }

  if (purchased) {
    actions = ["view-order"];
    if (claimRaised || allowSalesClaim) {
      actions.push("claim-button");
    } else {
      actions.push("download-invoice");
    }
  }

  return actions;
};

/**
 *
 * @param {string} status
 * @returns {string}
 */
export const getClaimStatusText = (status) => {
  const statusMap = {
    NotSaved: "Not Saved",
    Unsubmitted: "Unsubmitted",
    Submitted: "Submitted",
    Declined: "Declined",
    VendorOfferMade: "Vendor Offer Made",
    InformationRequested: "Information Requested",
    ReviewInProgress: "Review In Progress",
    Closed: "Closed",
    Expired: "Expired",
    Completed: "Completed",
    VendorOfferAccepted: "Vendor Offer Accepted",
  };
  return statusMap[status] || "";
};

/**
 *
 * @param {string} status
 * @returns {string}
 */
export const getStatusText = (status) => {
  const statusMap = {
    Outbid: "You’ve been outbid",
    HighestBidder: "Highest Bidder",
    Accepted: "Bid Accepted",
    Declined: "Bid Declined",
    Request: "Purchase Pending",
    PaymentPending: "Awaiting Payment",
    Dispatched: "Dispatched",
    PaymentReceived: "Payment Received",
    ComingSoon: "Coming Soon",
    FundingApproved: "Funding Approved",
  };
  return statusMap[status] || "";
};

/**
 *
 * @param {string} status
 * @returns {string}
 */
export const getStatusIcon = (status) => {
  const statusMap = {
    Outbid: "alert-circle",
    HighestBidder: "check-circle",
    Accepted: "check-circle",
    Declined: "alert-circle",
    Request: "stopwatch",
    PaymentPending: "alert-circle",
    Dispatched: "check-circle",
    PaymentReceived: "check-circle",
    ComingSoon: "stopwatch",
    FundingApproved: "check-circle",
  };
  return statusMap[status] || "";
};

/**
 *
 * @param {string} status
 * @returns {string}
 */
export const getBillingType = (billingType) => {
  const billingTypeMap = {
    AnnualRenewal: "Annual Renewal",
    TransactionMonthly: "Transaction (monthly)",
    TransactionIndividual: "Transaction (individual)",
    PrePayment: "Pre-Payment",
  };
  return billingTypeMap[billingType] || "";
};

/**
 *
 * @param {string} status
 * @param {number} count
 * @returns {string}
 */
export const getBidPriceBlockHeaderText = (status, count = 0) => {
  if (count > 0) {
    const statusMap = {
      HighestBidder: "Current Bid",
      Outbid: "Outbid",
      Declined: "Declined",
      Accepted: "Accepted Bid",
    };
    return statusMap[status] || "Current Bid";
  }
  return "Starting Bid";
};

export const getPurchaseBannerNotification = (status, text) => {
  const statusMap = {
    HighestBidder: {
      title: "You’re the highest bidder",
      text: "We will notify you if you are outbid on this vehicle",
      type: "success",
      isAlert: true,
    },
    Outbid: {
      title: "You’ve been outbid",
      text: "Increase your maximum bid below",
      type: "error",
      isAlert: true,
    },
    Request: {
      title: "Your purchase is pending",
      text: "Your purchase is being reviewed for approval",
      type: "caution",
      isAlert: true,
    },
    Declined: {
      title: "Bid Declined",
      text: "You can try placing a bid again from below",
      type: "error",
      isAlert: true,
    },
    Accepted: {
      title: "Bid Accepted",
      text: "Click the button below to complete the purchase",
      type: "caution",
      isAlert: true,
    },
    ComingSoon: {
      title: "Coming Soon",
      text: "",
      type: "stopwatch",
      isAlert: true,
    },
    PaymentPending: {
      title: "Awaiting Payment",
      text: text,
      type: "error",
      isAlert: true,
    },
    PaymentReceived: {
      title: "Payment Received",
      text: text,
      type: "success",
      isAlert: true,
    },
    Dispatched: {
      title: "Dispatched",
      text: text,
      type: "success",
      isAlert: true,
    },
    FundingApproved: {
      title: "Funding Approved",
      text: text,
      type: "success",
      isAlert: true,
    },
  };

  return statusMap[status];
};

/**
 *
 * @param {string} status
 * @param {boolean} bidFlag
 * @param {boolean} buyFlag
 * @returns boolean
 */
export const shouldHideShortlist = (status, bidFlag, buyFlag) => {
  return status !== "ForSale" || (!bidFlag && !buyFlag);
};

export const shouldHideAddVehicleNote = (
  status,
  bidFlag,
  buyFlag,
  isNotShortlisted,
) => {
  return status !== "ForSale" || (!bidFlag && !buyFlag) || isNotShortlisted;
};

/**
 * Computing the Stocklist filename for download.
 * @returns string
 */
export const getStocklistFilename = () => {
  let now = dayjs();
  const fromattedDate = now.format("DD_MM_YYYY_HH_mm");
  return `Stocklist_${fromattedDate}.csv`;
};

const PAGE_SIZE_OPTIONS = [
  { value: 25, label: 25, optionName: 25 },
  { value: 50, label: 50, optionName: 50 },
  { value: 100, label: 100, optionName: 100 },
  { value: 250, label: 250, optionName: 250 },
];

export const getPageSizeOptions = () => PAGE_SIZE_OPTIONS;

/**
 * @param {string} description
 * @returns string
 */
export const getFormattedServiceHistoryDesc = (description) => {
  return description.split(",").join(", ");
};

/** Regex for UK postcode */
export const POSTCODE_REGEX = /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,50}$/;
export const UK_TELEPHONE_REGEX =
  /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/;
export const SUPPORTINGLINK_REGEX = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
export const TIME_REGEX = /^([01]\d|2[0-3]):([0-5]\d)$/;
/**
 * Checking if an address has at least 2 lines and last one is a postocde
 * @param {string} address
 * @returns boolean
 */
export const isAddressValid = (address) => {
  if (!address) {
    return { isValid: false, error: "Address is empty" };
  }

  const addressArray = address.split("\n");
  if (addressArray.length <= 1) {
    return { isValid: false, error: "Address must have at least 2 lines" };
  }

  if (!addressArray[0].trim().length) {
    return { isValid: false, error: "First line of the address is empty" };
  }

  if (!POSTCODE_REGEX.test(addressArray[addressArray.length - 1].toUpperCase())) {
    return { isValid: false, error: "Last line is not a valid UK postcode" };
  }

  return { isValid: true, error: null };
};

/**
 * Formats a results list of purchased vehicles, consumed by the VehicleCard component
 * TODO: there is a lot of duplication between this and formatForSaleVehicleResultsResponse()
 * This could be refactored further
 * @param responseObject
 * @returns {{data: {}}|{pageCount: *, pageSize: *, totalCount: *, pageNumber: *, results: *}}
 */
export const formatPurchasedVehicleResultsResponse = (responseObject) => {
  const {
    data: { totalCount, pageNumber, pageSize, pageCount, vehicles },
  } = responseObject;

  if (vehicles && Array.isArray(vehicles)) {
    const normalisedResultsList = vehicles.reduce(
      (acc, item) => {
        const {
          imageCount,
          thumbnail,
          logo: vendorLogo,
          vehicleId,
          invoiceHeaderId,
          invoiceType,
          ...rest
        } = item;
        const titleFields = ["make", "range", "model", "derivative", "regNo"];
        const detailFields = [
          "dateOfRegistration",
          "odometer",
          "odometerType",
          "fuelType",
          "transmission",
          "colour",
          "capValuation",
          "capValuationDate",
          "buyerNotes",
        ];
        const keyInfoFields = [
          "serviceHistory",
          "v5",
          "mot",
          "motExpiryDate",
          "auctionGrade",
          "condition",
          "damagePercentage",
        ];
        const buyingOptionFields = [
          "purchasePrice",
          "vehiclePurchaseId",
          "saleStatus",
          "allowSalesClaim",
          "claimRaised",
          "claimId",
        ];

        return {
          byId: {
            ...acc.byId,
            [vehicleId]: {
              invoiceHeaderId,
              imageCount,
              thumbnail,
              vendorLogo,
              vehicleId,
              invoiceType,
              keyInfo: getResponseObjectProperties(rest, keyInfoFields, true),
              titles: getResponseObjectProperties(rest, titleFields, true),
              details: getResponseObjectProperties(rest, detailFields, true),
              buyingOptions: getResponseObjectProperties(
                rest,
                buyingOptionFields,
              ),
            },
          },
          vehicleIds: [...acc.vehicleIds, vehicleId],
        };
      },
      { byId: {}, vehicleIds: [] },
    );

    return {
      totalCount,
      pageNumber,
      pageSize,
      pageCount,
      results: normalisedResultsList,
    };
  }
  return {
    data: {},
  };
};

/**
 * Formats a results list of for-sale vehicles, consumed by the VehicleCard component
 * TODO: there is a lot of duplication between this and formatPurchasedVehicleResultsResponse()
 * This could be refactored further
 * @param responseObject
 * @returns {{data: {}}|{pageCount: *, pageSize: *, totalCount: *, pageNumber: *, results: *}}
 */
export const formatForSaleVehicleResultsResponse = (responseObject) => {
  const {
    data: { totalCount, pageNumber, pageSize, pageCount, vehicles },
  } = responseObject;

  if (vehicles && Array.isArray(vehicles)) {
    const normalisedResultsList = vehicles.reduce(
      (acc, item) => {
        const {
          imageCount,
          thumbnail,
          logo: vendorLogo,
          vehicleId,
          shortlisted,
          vehicleLocationPostCode,
          ...rest
        } = item;
        const titleFields = ["make", "derivative", "model", "range", "regNo"];
        const detailFields = [
          "capValuation",
          "capValuationDate",
          "colour",
          "dateOfRegistration",
          "fuelType",
          "odometer",
          "odometerType",
          "transmission",
          "buyerNotes",
        ];
        const keyInfoFields = [
          "auctionGrade",
          "condition",
          "mot",
          "motExpiryDate",
          "serviceHistory",
          "v5",
          "damagePercentage",
        ];
        const buyingOptionFields = [
          "allowBid",
          "allowBuyNow",
          "bidCount",
          "bidCurrentPrice",
          "bidStartPrice",
          "bidStatus",
          "biddingEndDateTime",
          "buyNowPrice",
          "currentStatus",
          "hasPreviousBid",
          "purchasingStatus",
          "comingSoon",
          "endDateTime",
          "endingToday",
          "campaignReference",
          "campaignId",
        ];
        const shortlistedVehicleIds = acc.shortlistedVehicleIds;

        if (shortlisted) {
          shortlistedVehicleIds.push(vehicleId);
        }

        return {
          byId: {
            ...acc.byId,
            [vehicleId]: {
              imageCount,
              thumbnail,
              vendorLogo,
              vehicleId,
              keyInfo: getResponseObjectProperties(rest, keyInfoFields, true),
              titles: getResponseObjectProperties(rest, titleFields, true),
              details: getResponseObjectProperties(rest, detailFields, true),
              buyingOptions: getResponseObjectProperties(
                rest,
                buyingOptionFields,
              ),
              vendorInfo: { vehicleLocation: vehicleLocationPostCode },
            },
          },
          vehicleIds: [...acc.vehicleIds, vehicleId],
          shortlistedVehicleIds,
        };
      },
      { byId: {}, vehicleIds: [], shortlistedVehicleIds: [] },
    );

    return {
      totalCount,
      pageNumber,
      pageSize,
      pageCount,
      results: normalisedResultsList,
    };
  }
  return {
    data: {},
  };
};

export const getContactAddressFromDeliveryOption = (deliveryOption) => {
  return deliveryOption ? deliveryOption.subtitle.split(", ") : [];
};

export const getPostCodeFromDeliveryOption = (deliveryOption) => {
  const address = getContactAddressFromDeliveryOption(deliveryOption);
  if (address && address.length) {
    return address[address.length - 1];
  }
  return null;
};

/**
 * Formats a results list of invoices
 * @param response
 * @returns {{data: {}}|{totalCount: *, pageNumber: *, pageSize: *, pageCount: *, results: *}}
 */
export const formatInvoicesResponse = (response) => {
  const {
    data: { totalCount, pageNumber, pageSize, pageCount, invoices },
  } = response;
  if (invoices && Array.isArray(invoices)) {
    const normalisedInvoices = invoices.reduce(
      (acc, invoice) => {
        return {
          byId: {
            ...acc.byId,
            [invoice.invoiceHeaderId]: { ...invoice },
          },
          invoiceIds: [...acc.invoiceIds, invoice.invoiceHeaderId],
        };
      },
      { byId: {}, invoiceIds: [] },
    );
    return {
      totalCount,
      pageNumber,
      pageSize,
      pageCount,
      results: normalisedInvoices,
    };
  }
  return {
    data: {},
  };
};

/**
 * Checks if a string contains only digits.
 * @param {string} str
 */
export const isNumber = (str) => /^\d+$/.test(str);

/**
 * Removes the locationName from the beggining of the address
 * @param {object} location
 */
export const getAddressWithoutLocationName = (location) => {
  if (location.companyName)
    return location.address.substr(location.address.indexOf("\n") + 1);
  return location.address;
};
/**
 *
 * @param {object} location
 */
export const getDeliveryOptionFromLocation = (location) => {
  const subtitle = getAddressWithoutLocationName(location).replace(/\n/g, ", ");
  return {
    title: location.companyName,
    subtitle: subtitle,
    value: "Delivery",
    code: "D",
    defaultOption: false,
  };
};

/**
 * Return status colour
 * @param {object} props
 */
export const getStatusColour = (props = {}) => {
  const { buyingOptions = {}, theme = {}, forText = false } = props;
  const { bidStatus, purchasingStatus, saleStatus, comingSoon } = buyingOptions;
  const DEFAULT = forText
    ? theme.COLOURS.PRIMARY.base
    : theme.COLOURS.SECONDARY.shades[20];
  const RED = theme.COLOURS.RED.base;
  const GREEN = theme.COLOURS.GREEN.base;
  const ORANGE = theme.COLOURS.ORANGE.base;
  if (comingSoon) return theme.COLOURS.ACCENT.base;
  if (!bidStatus && !purchasingStatus && !saleStatus) return DEFAULT;
  if (bidStatus === "Outbid") return RED;
  if (bidStatus === "HighestBidder") return GREEN;
  if (bidStatus === "Accepted") return ORANGE;
  if (bidStatus === "Declined") return RED;
  if (purchasingStatus && purchasingStatus === "Request") return ORANGE;
  if (saleStatus) {
    if (saleStatus === "PaymentPending") {
      return RED;
    } else {
      return GREEN;
    }
  }
  return DEFAULT;
};

/**
 *
 * @param {*} item
 * @param {number} index
 * @param {[*]} target
 * @return {[*]}
 */
export const insertItemAtIndex = (item, index = 1, target = []) => {
  let head = target.slice(0, index);
  const tail = target.slice(index);
  return head.concat(item).concat(tail);
};

/**
 *
 * @param {*} filterId
 * @param {[*]} target
 * @return {[*]}
 */
export const removeFilterFromArray = (filterId, target = []) => {
  let index = target.findIndex((el) => el.filterId === filterId);
  if (index > -1) {
    target.splice(index, 1);
  }
  return target;
};

/**
 *
 * @param {[*]} options
 * @param {[*]} selectedOptions
 * @return {string}
 */
export const getSelectedItemsString = (options = [], selectedOptions = []) => {
  const selected = selectedOptions.values.map(
    (selectedValue) =>
      options.filter((option) => option.value === selectedValue)[0].optionName,
  );
  const selectedString = selected.join(", ");

  return selectedString;
};

/**
 * Set viewport in meta tag to fix keyboard viewport on Android devices
 */
export const setViewportHeight = () => {
  const viewport = document.querySelector("meta[name=viewport]");

  viewport.setAttribute(
    "content",
    viewport.content + ", height=" + window.innerHeight,
  );
};

/**
 * Get the userAgent
 */
export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (
    /iPad|iPhone|iPod/.test(navigator.platform) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  ) {
    return "ios";
  } else if (userAgent.match(/Android/i)) {
    return "android";
  } else {
    return "unknown";
  }
};

/**
 * Make accurate vh available to fix Android keyboard issue
 */
export const viewHeightReset = () => {
  document.documentElement.style.setProperty(
    "--vh",
    `${window.innerHeight * 0.01}px`,
  );

  window.addEventListener("orientationchange", () => {
    const afterOrientationChange = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`,
      );

      window.removeEventListener("resize", afterOrientationChange);
    };
    window.addEventListener("resize", afterOrientationChange);
  });
};

export const getWhiteLabelTheme = (theme) =>
  WHITE_LABEL_THEME_MAP[theme] || "TradeBuyer";

const WHITE_LABEL_THEME_MAP = {
  vw: "Volkswagen",
  audi: "Audi",
  seat: "Seat",
  skoda: "Skoda",
  vwcv: "VolkswagenCommercial",
  mflda: "Motability",
};

/**
 * Function to open a new tab and await for it's content to be populated later.
 * This is used for awaiting pdfs to be generated and showed in a new tab without triggerring the pop-up blocker browser functionalty.
 * Pop-up blocker bypasser method for server generated pdfs.
 * @param {string} loadingText
 */
export const createLoadingTabForPDFDownload = (loadingText) => {
  const tab = window.open("", "_blank");
  var tag = document.createElement("P");
  var t = document.createTextNode(loadingText);
  tag.appendChild(t);
  tab.document.body.appendChild(tag);
  return tab;
};

export const addNotePopOverOptionsCardView = {
  panel: {
    options: {},
    css: {
      width: "50rem",
      left: "-43rem",
    },
  },
  panelCloseBtn: {
    options: { show: false },
  },
};

export const addNotePopOverOptionsCardViewMobile = {
  panel: {
    options: {},
    css: {
      width: "27rem",
      left: "-12rem",
    },
  },
  panelCloseBtn: {
    options: { show: false },
  },
};

export const USER_TYPE_MAP = {
  Buyer: "Buyer",
  ProductSupport: "ProductSupport",
  Vendor: "Vendor",
};

export const VENDOR_LANDING_PAGE_TYPE_MAP = {
  ClaimsAdministration: "claims",
  CampaignManagement: "campaigns",
  VehicleRecord: "vehicleRecord"
};

/**
 * Checking if a number string is float.
 * @param {string} numberString
 */
export const isFloat = (numberString) => numberString.indexOf(".") !== -1;

export const LOGISTICS_STATUS_MAP = {
  Completed: "SUCCESS",
  Warning: "CAUTION",
  NotCompleted: "ERROR",
};

export const CLAIM_STATUS_TYPE = {
  UNSAVED: "Unsaved",
  UNSUBMITTED: "Unsubmitted",
  SUBMITTED: "Submitted",
  DECLINED: "Declined",
  VENDOR_OFFER_MADE: "VendorOfferMade",
  INFORMATION_REQUESTED: "InformationRequested",
  REVIEW_IN_PROGRESS: "ReviewInProgress",
  REVIEW_COMPLETE: "ReviewInProgress",
  CLOSED: "Closed",
  EXPIRED: "Expired",
  COMPLETED: "Completed",
  VENDOR_OFFER_ACCEPTED: "VendorOfferAccepted",
};

export const CLAIM_STATUS_TYPES_TO_HIDE_ACTIONS = [
  CLAIM_STATUS_TYPE.DECLINED,
  CLAIM_STATUS_TYPE.INFORMATION_REQUESTED,
  CLAIM_STATUS_TYPE.VENDOR_OFFER_ACCEPTED,
];

/**
 *
 * @param {string} status
 * @returns {string}
 */
export const getSaleCategoryText = (status) => {
  const statusMap = {
    EarlyTermination: "Early Termination",
    MainStock: "Main Stock",
    NonUkVehicle: "Non-UK Vehicle",
    ThirdParty: "3rd Party",
    WAVStock: "WAV Stock",
    BLine: "B-Line",
    StandardStock: "Standard Stock",
  };
  return statusMap[status] || status;
};

/**
 *
 * @param {number} vendorOffer
 * @param {boolean} offerSaleCancellation
 * @returns {string}
 */
export const getClaimOfferText = (vendorOffer, offerSaleCancellation) => {
  let text = vendorOffer ? `£${formatPrice(vendorOffer)}` : "";
  text += vendorOffer && offerSaleCancellation ? " or " : "";
  text += offerSaleCancellation ? "Sale Cancellation" : "";
  return text ? text : "-";
};

export const CLAIM_ACCEPTANCE_TYPE = {
  SALE_CANCELLATION: "SaleCancellation",
  AMOUNT_ACCEPTED: "AmountAccepted",
};

/**
 * Returns a value string for displaying in a select component
 * @param {Object} sortObject
 * @returns {string}
 */
export const getCampaignStatus = (campaignStatus = {}) => {
  switch (campaignStatus) {
    case "New":
      return "New Campaign";
    case "Pending":
      return "Pending Campaign";
    case "Live":
      return "Live Campaign";
    case "Expired":
      return "Expired Campaign";
    default:
      return "Unsubmitted";
  }
};

export const getDownloadCampaignsFilename = () => {
  let now = dayjs();
  const fromattedDate = now.format("YYYYMMDD_HHmm");
  return `Campaigns_${fromattedDate}.csv`;
};

export const registerServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistration().then((existingRegistration) => {
      if (existingRegistration) {
      } else {
        navigator.serviceWorker
          .register("./service-worker.js")
          .then((registration) => { })
          .catch((error) => {
            console.error("Error registering Service Worker:", error);
          });
      }
    });
  }
};

export const notificationPreferenceCategoryMappings = {
  messages: "Messages",
};

export const getOS = () => {
  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null,
    version = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
    version = /Mac OS X (\d+[._]\d+)/.exec(userAgent);
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
    version = /OS (\d+[_]\d+)/.exec(userAgent);
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
    version = /Windows NT (\d+[._]\d+)/.exec(userAgent);
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
    version = /Android (\d+[._]\d+)/.exec(userAgent);
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
    version = /Linux (\d+[._]\d+)/.exec(userAgent);
  }
  version = version ? version[1].replace('_', '.') : 'Unknown';
  return { os, version };
}

export const getBrowser = () => {
  let userAgent = navigator.userAgent;
  let browserName = "Unknown";
  let version = "Unknown";

  if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Mozilla Firefox";
    version = /Firefox\/(\d+\.\d+)/.exec(userAgent);
  } else if (userAgent.indexOf("SamsungBrowser") > -1) {
    browserName = "Samsung Internet";
    version = /SamsungBrowser\/(\d+\.\d+)/.exec(userAgent);
  } else if (userAgent.indexOf("OPR") > -1 || userAgent.indexOf("Opera") > -1) {
    browserName = "Opera";
    version = /OPR\/(\d+\.\d+)|Opera\/(\d+\.\d+)/.exec(userAgent);
  } else if (userAgent.indexOf("Trident") > -1) {
    browserName = "Microsoft Internet Explorer";
    version = /rv:(\d+\.\d+)/.exec(userAgent);
  } else if (userAgent.indexOf("Edg") > -1 || userAgent.indexOf("Edge") > -1) {
    browserName = "Microsoft Edge";
    version = /Edg\/(\d+\.\d+)|Edge\/(\d+\.\d+)/.exec(userAgent);
  } else if (userAgent.indexOf("CriOS") > -1) {
    browserName = "Google Chrome";
    version = /CriOS\/(\d+\.\d+)/.exec(userAgent);
  } else if (userAgent.indexOf("FxiOS") > -1) {
    browserName = "Mozilla Firefox";
    version = /FxiOS\/(\d+\.\d+)/.exec(userAgent);
  } else if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Edg") === -1) {
    browserName = "Google Chrome";
    version = /Chrome\/(\d+\.\d+)/.exec(userAgent);
  } else if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1 && userAgent.indexOf("CriOS") === -1) {
    browserName = "Apple Safari";
    version = /Version\/(\d+\.\d+)/.exec(userAgent);
  } else if (userAgent.indexOf("UCBrowser") > -1) {
    browserName = "UC Browser";
    version = /UCBrowser\/(\d+\.\d+)/.exec(userAgent);
  } else if (userAgent.indexOf("QQBrowser") > -1) {
    browserName = "QQ Browser";
    version = /QQBrowser\/(\d+\.\d+)/.exec(userAgent);
  } else if (userAgent.indexOf("Baidu") > -1) {
    browserName = "Baidu Browser";
    version = /Baidu\/(\d+\.\d+)/.exec(userAgent);
  }

  version = version ? version[1] : 'Unknown';
  return { browserName, version };
}

export const getInspectionType = (hasInspection) => {
  switch (hasInspection) {
    case "true":
      return "Yes";
    case "false":
      return "No";
    default:
      return "";
  }
};

export const getCurrentStatusType = (status) => {
  const statusMap = {
    ForSale: "For Sale",
    Deleted: "Deleted",
    Disposed: "Disposed",
    InLife: "In Life",
    SaleAgreed: "Sale Agreed",
    Terminated: "Terminated",
  };
  return statusMap[status] || "";
}

export const getIconProps = (condition, positiveColor, negativeColor, equalColor = null, style = {}) => {
  let iconStyle = { ...style, marginTop: "0.7rem" };
  let iconColor = "";
  let iconType = "arrow-download"

  if (condition === "greater" || condition === true) {
    iconType = "arrow-up";
    iconColor = positiveColor;
  } else if (condition === "equal") {
    iconType = "equals-circle";
    iconColor = equalColor || negativeColor;
  } else {
    iconColor = negativeColor;
  }

  return { iconStyle, iconColor, iconType };
};

export const getBiddingType = (biddingType) => {
  switch (biddingType.toUpperCase()) {
    case "O":
      return "Open Ended Bidding";
    case "T":
      return "Timed Bidding";
    default:
      return "";
  }
};

const DISPOSAL_CHANNEL_MAP = {
  Auction: "Auction",
  TradeOnline: "Trade Online",
  DriverStaff: "Driver Staff",
  DirectSalesSite: "Direct Sales Site",
  TradeOffline: "Trade Offline"
};

export const getDisposalChannelText = (method) => DISPOSAL_CHANNEL_MAP[method] || "";

export const VENDOR_VEHICLE_LOCATION_MAP = {
  HISTORY: 0,
  CURRENT_LOCATION: 1,
  DRIVER_LOCATION: 2,
  CUSTOMER_LOCATION: 3,
  DEFLEET_LOCATION: 4,
  DRIVER_AND_CUSTOMER_LOCATION: 5,
}

const VEHICLE_LOCATION_TYPE_MAP = {
  AuctionArrival: "Auction Arrival",
  DriverLocation: "Driver Location",
  LogisticsDestination: "Logistics Destination",
  LogisticsSource: "Logistics Source",
  ManualCreation: "Manual Creation",
  SourceLocation: "Source Location",
  VehicleArrival: "Vehicle Arrival",
  LoadedLocation: "Loaded Location",
  PurchaseDelivery: "Purchase Delivery",
  ClaimLocation: "Claim Location"
};

export const getVehicleLocationTypeText = (method) => VEHICLE_LOCATION_TYPE_MAP[method] || "";

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text).then(
    () => {
      toast.success(`Copied to clipboard!`, {
        autoClose: 2000,
        transition: Bounce,
      });
    },
    (err) => { }
  );
};

const auctionSaleStatusMap = {
  C: "Complete",
  F: "Locked Sale",
  N: "New Sale",
  O: "Confirm Completion",
  P: "Complete with Provisional Sales",
  V: "Complete with Vendor Revisions"
};

export const getAuctionSalesStatus = (auctionStatus) => {
  return auctionSaleStatusMap[auctionStatus] || "Unknown Status";
};

export const VENDOR_VEHICLE_NAVIGATION_OPTIONS = {
  VEHICLE_DETAILS: "Vehicle Details",
  SALES_INFO: "Sales Information",
  PURCHASE_INFO: "Purchase Information",
  BIDDING_INFO: "Bidding Information",
  PROFILES: "Profiles",
  AUCTION_SALES: "Auction Sales",
  DRIVER_CUSTOMER: "Customer/Driver",
  VEHICLE_LOCATION: "Vehicle Location",
  EQUIPMENT_AND_SPEC: "Equipment & Spec",
  EVENT_HISTORY: "Event History",
  MAINTENANCE_HISTORY: "Maintenance History",
  INSPECTION_REPORT: "Inspection Report",
  DOCUMENTATION: "Documentation",
  STANDARD_EQUIPMENT: "Standard",
  TECHNICAL_SPECIFICATION: "Technical",
  ADDITIONAL_EQUIPMENT: "Additional",
};

export const ACTION_AREAS = {
  VEHICLE_DETAILS: "Vehicle Details",
  SALES_INFO: "Sales Information",
  PURCHASE_INFO: "Purchase Information",
  BIDDING_INFO: "Bidding Information",
  PROFILES: "Profiles",
  AUCTION_SALES: "Auction Sales",
  DRIVER_CUSTOMER: "Customer/Driver",
  VEHICLE_LOCATION: "Vehicle Location",
  EQUIPMENT_AND_SPEC: "Equipment & Spec",
  EVENT_HISTORY: "Event History",
  MAINTENANCE_HISTORY: "Maintenance History",
  INSPECTION_REPORT: "Inspection Report",
  DOCUMENTATION: "Documentation",
  STANDARD_EQUIPMENT: "Standard Equipment",
  TECHNICAL_SPECIFICATION: "Technical Specification",
  ADDITIONAL_EQUIPMENT: "Additional Equipment",
};


export const ActionAreaEnum = {
  VehicleDetails: { value: 1, description: ACTION_AREAS.VEHICLE_DETAILS },
  SalesInfo: { value: 2, description: ACTION_AREAS.SALES_INFO },
  PurchaseInfo: { value: 3, description: ACTION_AREAS.PURCHASE_INFO },
  BiddingInfo: { value: 4, description: ACTION_AREAS.BIDDING_INFO },
  Profiles: { value: 5, description: ACTION_AREAS.PROFILES },
  AuctionSales: { value: 6, description: ACTION_AREAS.AUCTION_SALES },
  DriverAndCustomer: { value: 7, description: ACTION_AREAS.DRIVER_CUSTOMER },
  VehicleLocation: { value: 8, description: ACTION_AREAS.VEHICLE_LOCATION },
  EquipmentAndSpec: { value: 9, description: ACTION_AREAS.EQUIPMENT_AND_SPEC },
  EventHistory: { value: 10, description: ACTION_AREAS.EVENT_HISTORY },
  MaintenanceHistory: { value: 11, description: ACTION_AREAS.MAINTENANCE_HISTORY },
  InspectionReport: { value: 12, description: ACTION_AREAS.INSPECTION_REPORT },
  Documentation: { value: 13, description: ACTION_AREAS.DOCUMENTATION },
  StandardEquipment: { value: 14, description: ACTION_AREAS.STANDARD_EQUIPMENT },
  TechnicalSpecification: { value: 15, description: ACTION_AREAS.TECHNICAL_SPECIFICATION },
  AdditionalEquipment: { value: 16, description: ACTION_AREAS.ADDITIONAL_EQUIPMENT }
};

export const getActionAreaTypeFromDescription = (description) => {
  for (const key in ActionAreaEnum) {
    if (ActionAreaEnum[key].description === description) {
      return key;
    }
  }
  return null;
};

export const getActionAreaValueFromDescription = (description) => {
  for (const key in ActionAreaEnum) {
    if (ActionAreaEnum[key].description === description) {
      return ActionAreaEnum[key].value;
    }
  }
  return null;
};

export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};
export const convertToNumber = (val) => {
  if (isNaN(val)) {
    return (val = 0);
  }
  return Number(val);
};

export const odometerTypeDropdownOptions = [{
  label: "miles",
  value: "miles"
},
{
  label: "hours",
  value: "hours"
},
{
  label: "kilometres",
  value: "kilometres"
}]

export const getShowMeFilterDotColor = (optionName, theme) => {
  switch (optionName) {
    case "Beige":
      return theme.COLOURS.BEIGE;
    case "Black":
      return theme.COLOURS.BLACK;
    case "Blue":
      return theme.COLOURS.BLUE;
    case "Bronze":
      return theme.COLOURS.BRONZE;
    case "Brown":
      return theme.COLOURS.BROWN;
    case "Cream":
      return theme.COLOURS.CREAM;
    case "Gold":
      return theme.COLOURS.GOLD;
    case "Green":
      return theme.COLOURS.GREEN.base;
    case "Grey":
      return theme.COLOURS.GREY;
    case "Maroon":
      return theme.COLOURS.MAROON;
    case "Orange":
      return theme.COLOURS.ORANGE.base;
    case "Pink":
      return theme.COLOURS.PINK;
    case "Purple":
      return theme.COLOURS.PURPLE;
    case "Red":
      return theme.COLOURS.RED.base;
    case "Silver":
      return theme.COLOURS.SILVER;
    case "Turquoise":
      return theme.COLOURS.TURQUOISE;
    case "White":
      return theme.COLOURS.WHITE;
    case "Yellow":
      return theme.COLOURS.YELLOW;
    default:
      return optionName; //This gives us the option to use a colour name as a colour as a backup (if it doesn't work, black is used, the same as if we used null)
  }
};

// These colours are generic from the DVLA API, they shouldn't be amended without first confirming the value can be provided by the DVLA.
export const genericColours = [
  "Beige",
  "Black",
  "Blue",
  "Bronze",
  "Brown",
  "Cream",
  "Gold",
  "Green",
  "Grey",
  "Maroon",
  "Orange",
  "Pink",
  "Purple",
  "Red",
  "Silver",
  "Turquoise",
  "White",
  "Yellow",
];

export const ValuationReason = {
  UserRequest: 0,
  VehicleCreated: 1,
  VehicleReturned: 2,
  VehicleUpdated: 3,
  DriverQuotation: 4,
  VehicleCollected: 5,
  VehicleSold: 6,
  BestBuyRating: 7,
  AutoPricing: 8,
  ManualAddition: 9,
  MonthEndValuation: 10,
  TeamPricing: 11,
};