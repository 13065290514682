import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../../state/reducers/global";
import ModalInsert from "../../components/ModalInsert";
import GenericConfirmInsert from "../../components/GenericConfirmInsert";
import { clearActiveConfirmModalOptions } from "../../../state/reducers/vendor-vehicle/genericActiveState";
import {
  acceptBid,
  declineBid,
} from "../../../state/reducers/vendor-vehicle/biddingInformation";
import {
  copyDriverLocationData,
  copyCustomerLocationData,
} from "../../../state/reducers/vendor-vehicle/location";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../views/theme/breakpoints";
import { deleteVehicleDocumentation } from "../../../state/reducers/vehicle";
import { removeVehicleHold } from "../../../state/reducers/vendor-vehicle/vehicleHold";
import { removeAllVehicleImages } from "../../../state/reducers/vendor-vehicle/vehicleImages";
import { vehicleMenuAction } from "../../../state/reducers/vendor-vehicle/vehicleDetails";
import { includeInAutoProfiling } from "../../../state/reducers/vendor-vehicle/profiles";

const VendorVehicleRecordGenericAreYouSureModal = () => {
  const dispatch = useDispatch();

  const [isActionProcessing, setIsActionProcessing] = useState(false);

  const { type, data } = useSelector(
    (state) =>
      state.vendorVehicleRecord.genericActiveState.activeConfirmModalOptions
  );
  // type is useful when there are same type of actions in multiple area, unless people name their actions right and unique

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  const getBodyTextToDisplay = (action) => {
    switch (action) {
      case "accept-bid":
        return `You are about to accept a bid of ${data.bidAmount} from ${data.buyerName}, are you sure?`;
      case "decline-bid":
        return `You are about to decline a bid of ${data.bidAmount} from ${data.buyerName}, are you sure?`;
      case "delete-document":
        return `Are you sure you want to delete ${data.documentName}?`;
      case "release-hold":
        return `You are about to release the hold on the vehicle, are you sure?`;
      case "release-workshop":
        return `You are about to release the vehicle from the workshop, are you sure?`;
      case "copy-driver-location":
        return `Are you sure you want to copy the driver location data and replace the customer location data?`;
      case "copy-customer-location":
        return `Are you sure you want to copy the customer location data and replace the driver location data?`;
      case "remove-from-sale":
        return `You are about to remove the vehicle from sale, are you sure?`;
      case "cancel-payment":
        return `Are you sure you want to cancel the payment?`;
      case "cancel-return":
        return `Are you sure you want to cancel return?`;
      case "delete-all-images":
        return `Are you sure you want to delete all images?`;
      case "cancel-defleet":
        return `Are you sure you want to cancel defleet?`;
      case "get-generic-colour":
        return `Are you sure you want to get the generic colour? This will fetch the colour from the DVLA and refresh this page, any changes will be lost.`;
      case "include-in-auto-profile":
        return `Including in auto profiling will remove the manually allocated ${data.profileName} profile.`;
      case "terminate-vehicle":
        return `Are you sure you want to defleet the vehicle?`;
      default:
        return "";
    }
  };

  const actionMap = {
    "accept-bid": () => dispatch(acceptBid(data.vehicleReference, data.bidId)),
    "decline-bid": () =>
      dispatch(declineBid(data.vehicleReference, data.bidId)),
    "delete-document": () =>
      dispatch(
        deleteVehicleDocumentation(
          data.vehicleReference,
          data.vehicleDocumentId
        )
      ),
    "copy-driver-location": () =>
      dispatch(
        copyDriverLocationData(data.vehicleReference, data.completeData)
      ),
    "copy-customer-location": () =>
      dispatch(
        copyCustomerLocationData(data.vehicleReference, data.completeData)
      ),
    "release-hold": () => dispatch(removeVehicleHold(data.vehicleReference)),
    "delete-all-images": () => {
      const handleData = {
        vehicleId: data.vehicleReference,
        blobIds: data.images,
      };
      dispatch(removeAllVehicleImages(handleData));
    },
    "release-workshop": () =>
      dispatch(
        vehicleMenuAction({
          actionType: data.action,
          vehicleId: data.vehicleReference,
          actionData: null,
        })
      ),
    "remove-from-sale": () =>
      dispatch(
        vehicleMenuAction({
          actionType: data.action,
          vehicleId: data.vehicleReference,
          actionData: null,
        })
      ),
    "cancel-payment": () =>
      dispatch(
        vehicleMenuAction({
          actionType: data.action,
          vehicleId: data.vehicleReference,
          actionData: null,
        })
      ),
    "cancel-return": () =>
      dispatch(
        vehicleMenuAction({
          actionType: data.action,
          vehicleId: data.vehicleReference,
          actionData: null,
        })
      ),
    "cancel-defleet": () =>
      dispatch(
        vehicleMenuAction({
          actionType: data.action,
          vehicleId: data.vehicleReference,
          actionData: null,
        })
      ),
    "get-generic-colour": () =>
      dispatch(
        vehicleMenuAction({
          actionType: data.action,
          vehicleId: data.vehicleReference,
          actionData: null,
        })
      ),
    "include-in-auto-profile": () =>
      dispatch(includeInAutoProfiling(data.vehicleReference, !data.autoProfile ? 1 : 0, data.profileId)),
    "terminate-vehicle": () =>
      dispatch(
        vehicleMenuAction({
          actionType: data.action,
          vehicleId: data.vehicleReference,
          actionData: null,
        })
      ),
  };

  const onConfirm = () => {
    if (actionMap[data.action]) {
      actionMap[data.action]();
      setIsActionProcessing(true);
    }
  };

  const onCancel = () => {
    if (data.action === "delete-all-images") {
      dispatch(openModal("vendor-vehicle-images-modal"));
    } else {
      dispatch(closeModal());
    }
    dispatch(clearActiveConfirmModalOptions());
  };

  return (
    <Fragment>
      <ModalInsert width={isDesktop ? "55rem" : "100%"}>
        <GenericConfirmInsert
          headerText="Are you sure?"
          bodyText={getBodyTextToDisplay(data.action)}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      </ModalInsert>
    </Fragment>
  );
};

export default VendorVehicleRecordGenericAreYouSureModal;
