import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import HeaderText from "../../../views/components/HeaderText";
import ParagraphText from "../../../views/components/ParagraphText";
import Datepicker from "../../../views/components/Datepicker";
import dayjs from "dayjs";
import { closeModal } from "../../../state/reducers/global";
import BidInputError from "../../../views/components/BidInput/child-components/BidInputError";
import { headers, errors, actions } from "./const";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../../views/theme/breakpoints";
import Button from "../../../views/components/Button";

const VendorVehicleRecordReturnVehicleModal = ({
  className,
  onConfirmHandle,
}) => {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_PHONE})`,
  });
  const [returnDate, setReturnDate] = useState(
    dayjs().format("YYYY-MM-DDTHH:mm")
  );
  const [validReturnDate, setValidReturnDate] = useState(true);

  const handleDateChange = (date) => {
    const finalFormattedDate = dayjs(date).format("YYYY-MM-DDTHH:mm");
    validReturnDateCheck(finalFormattedDate);
    setReturnDate(finalFormattedDate);
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const validReturnDateCheck = (date) => {
    if (!date || !dayjs(date, "YYYY-MM-DDTHH:mm", true).isValid()) {
      setValidReturnDate(false);
    } else {
      setValidReturnDate(true);
    }
  };

  const validReturnData = returnDate && validReturnDate;

  return (
    <Fragment>
      <div className={className}>
        <div className="return-modal-content">
          <div className="return-modal-header">
            <HeaderText>{headers.returnVehicle}</HeaderText>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              gap: "5rem",
            }}
          >
            <div className="detail-list">
              <div className="row">
                <ParagraphText>{headers.returnDate}</ParagraphText>
                <Datepicker
                  getDate={handleDateChange}
                  defaultDate={returnDate || ""}
                  setValidValue={setValidReturnDate}
                  primary
                />
              </div>
              {returnDate && !validReturnDate && (
                <BidInputError errorMessage={errors.validDate} />
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: isDesktop ? "flex-end" : "space-between",
              paddingTop: "5rem",
            }}
          >
            <Button
              ghost
              content={actions.close}
              action={onCancel}
              style={{ flex: 1 }}
            />
            <Button
              primary
              download
              content={actions.confirm}
              action={() => onConfirmHandle(returnDate)}
              disabled={!validReturnData}
              style={{ flex: 1 }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VendorVehicleRecordReturnVehicleModal;
