export const headers = {
    publish: "Publish Vehicle",
    biddingEndDate: 'Bidding End Date',
    biddingEndTime: 'Bidding End Time',
    buyNowPrice: 'Buy Now',
    bidStartAtPrice: 'Bid Start At',
    auctionReservePrice: 'Auction Reserve',
    onlineReservePrice: 'Online Reserve',
    retailPrice: 'Retail',
    capValuation: 'CAP Valuation',
    allowBuyNow: 'Allow Buy Now',
};

export const sessionTypeLabels = {
    fixedPrice: 'Fixed Price',
    timed: 'Timed Bidding',
    openEnded: 'Open Ended Bidding'
};

export const errors = {
    validDate: 'Please enter a valid date',
    validTime: 'Please enter a valid time',
    dateInPast: 'Date cannot be in the past',
    timeInPast: 'Time cannot be in the past',
};

export const validationErrorMessages = {
    vatStatusRequired: 'You must have a VAT Status to publish a vehicle',
    salesAccountRequired: 'You must have a sales account to publish a vehicle',
    mustHaveProfile: 'You must have a profile to publish a vehicle',
    auctionReservePriceRequired: 'Auction Reserve Price must be greater than zero',
    buyNowPriceRequired: 'Buy Now Price must be greater than zero',
    onlineProfileOrAllocatedToSite: 'You must have an online profile or the vehicle must be allocated to a site to publish',
    biddingWithFbs: 'You cannot publish a vehicle as bidding when the profile contains a Final Bidding Session entry'
};

export const validationSuggestionMessages = {
    capCodeRecommended: 'Valid CAP Code Recommended',
    capValuationUnder: 'Price is below 85% of CAP',
    capValuationOver: 'Price is above 120% of CAP',
};

export const validationLabels = {
    vatStatus: 'VAT Status',
    salesAccount: 'Sales Account',
    profile: 'Profile',
    buyNowPrice: 'Buy Now Price',
    auctionReservePrice: 'Auction Reserve Price',
    biddingWithFbs: 'No Final Bidding Session',
    profileOrSite: 'Profile or Auction Allocation',
    terminated: 'Terminated',
};

export const actions = {
    confirm: 'Publish',
    close: 'Close',
};
