import React from "react";
import ParagraphText from "./../../../views/components/ParagraphText";
import ButtonLink from "./../../../views/components/ButtonLink";

const GenericInformation = ({
  className,
  informationData,
  columns,
  getFormattedValue = (key, value) => value,
}) => {
  const getLabel = (item, data) => {
   // Check if alternateLabel exists and the condition is met
   if (item.alternateLabel && item.key === "bid" && data.resultDescription !== "Sold") {
     return item.alternateLabel;
   }
   return item.label;
  };

  return (
    <div className={className}>
      <div className="info-options-containers">
        {columns.map((column, columnIndex) => (
          <div className="info-options-containers-column" key={columnIndex}>
            {column.map((item, index) => (
              <div
                className={`info-options-row ${item.isTitle ? 'info-options-title' : ''}`}
                key={index}
              >
                <ParagraphText className={item.isTitle ? 'title-label' : ''}>
                 {getLabel(item, informationData)}
                </ParagraphText>
                {!item.isTitle && (
                  <div className="generic-info-item">
                    <ParagraphText
                      className={`info-returnedValue`}
                    >
                      {getFormattedValue(item.key, informationData[item.key])}
                    </ParagraphText>
                    
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default GenericInformation;