import Api from "./Base";
import settings from "../clientappsettings.json";

class UserCustomisation extends Api {
    baseUrl = settings.USERS_URL;
    headers = {
        Authorization: `Bearer ${this.getCredentials()}`,
    };
}

export class FetchUserCustomisation extends UserCustomisation {
    requestUrl = `/User/UserCustomisation/FetchUserCustomisation`;
    method = "POST";
    formatResponseData(response) {
        return response?.data;
    }
}

export class UpdateUserCustomisation extends UserCustomisation {
    requestUrl = `/User/UserCustomisation/UpdateUserCustomisation`;
    method = "POST";
}

export class RemoveUserCustomisation extends UserCustomisation {
    requestUrl = `/User/UserCustomisation/RemoveUserCustomisation`;
    method = "POST";
}