import React, { useEffect, useState, useContext, useLayoutEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "styled-components";
import { isTokenValid } from "../../../shared/utility";
import useIsLoggedInUserType from "../../../shared/hooks/useIsLoggedInUserType";
import { USER_TYPE_MAP } from "../../../shared/utility";
import { gADataLayerPushMotability } from "../../../shared/utilityGA";
import {
  BidsAndRequests,
  Home,
  Invoices,
  ActivityLog,
  Login,
  ForgottenUsername,
  ForgottenPassword,
  LoginVWFS,
  Messages,
  PurchaseConfirmation,
  Purchases,
  PurchasesArchive,
  Shortlist,
  TermsAndConditions,
  TermsAndConditionsUpdates,
  VehicleDetails,
  ShowroomDetails,
  AccountAdministration,
  ChangePassword,
  ResetPassword,
  NotFound,
  HelpCentre,
  NotificationPreference,
  ProductSupportHome,
  ProductSupportDummy,
  Claims,
  Claim,
  ClaimsHomePage,
  NewClaims,
  InProgressClaims,
  CallbackRequestedClaims,
  OfferActionClaims,
  VendorClaimDetails,
  VendorVehicleDetails,
  CompletedClaims,
  CampaignManagement,
  CreateCampaign,
  VendorAuctionView,
  ModifyVehicleDetails,
  VehicleSearch,
} from "../../pages";
import {
  setAuthenticationFromUrl,
  clearAuthentication,
  setRedirectUrl,
} from "../../../state/reducers/authentication";
import {
  registerNavigationEvent,
  registerVendorNavigationEvent,
} from "../../../state/reducers/global";
import PrivateRoute from "./PrivateRoute";
import AdministratorRoute from "./AdministratorRoute";
import ProductSupportRoute from "./ProductSupportRoute";
import VendorRoute from "./VendorRoute";
import LoadingTransition from "./../../components/LoadingTransition";
import { vendorPaths } from "../../../vendor/shared/consts";
import Campaigns from "../../../vendor/components/CampaignManagement/Campaigns/Campaigns";
import { setNotificationData } from "../../../state/reducers/global";
import { toast, Bounce } from "react-toastify";
import NotificationBell from "../../components/Icon/child-components/NotificationBell";

const RoutesContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useContext(ThemeContext);
  const location = useLocation();
  const { pathname } = location;

  const specialPaths = ["/vendor/vehicle/"]; // Add more special paths as needed in the future

  const containsSpecialPath = (pathname, paths) => {
    return pathname && paths.some((path) => pathname.includes(path));
  };

  const [checkJWT, setCheckJWT] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const jwtToken = useQuery().get("JWT");
  useEffect(() => {
    if (jwtToken) {
      dispatch(clearAuthentication());
      if (isTokenValid(jwtToken)) {
        dispatch(setAuthenticationFromUrl(jwtToken, navigate));
      }
    }
    setCheckJWT(true);
  }, [dispatch, jwtToken, navigate]);

  const redirectUrl =
    jwtToken && containsSpecialPath(location?.pathname, specialPaths)
      ? location?.pathname
      : jwtToken
        ? null
        : `${location?.pathname}${location?.search}`;

  useEffect(() => {
    if (redirectUrl && !redirectUrl.includes("/login") && redirectUrl !== "/") {
      dispatch(setRedirectUrl(redirectUrl));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoggingOut = useSelector((state) => state.global.isLoggingOut);
  const isLoggedInBuyer = useIsLoggedInUserType(USER_TYPE_MAP.Buyer);

  useLayoutEffect(() => {
    if (isLoggedInBuyer) {
      dispatch(registerNavigationEvent(pathname));
    } else {
      dispatch(registerVendorNavigationEvent(pathname));
    }
  }, [dispatch, pathname, isLoggedInBuyer]);

  const onClickNavigate = (message) => {
    dispatch(setNotificationData(message));
    navigate(message.subPath ? message.subPath : "/", { state: message });
  };

  const Icon = <NotificationBell strokeColour={theme.COLOURS.WHITE} />;

  const notificationToast = (message) => {
    const messageText = (
      <div>
        <strong>{message.title}</strong>
        <br /> {message.body}
      </div>
    );
    toast(messageText, {
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      icon: Icon,
      transition: Bounce,
      onClick: () => onClickNavigate(message),
      theme: "dark",
      style: {
        fontFamily: "'Lato', Arial, Helvetica, sans-serif",
        color: theme.COLOURS.WHITE,
        backgroundColor: theme.COLOURS.PRIMARY.base,
      },
      progressStyle: { background: theme.COLOURS.WHITE },
    });
  };

  useEffect(() => {
    // Listen for messages from service worker
    navigator.serviceWorker.addEventListener("message", (event) => {
      const { type, message } = event.data;
      if (type === "onNotificationClick") {
        dispatch(setNotificationData(message));
      } else if (type === "toast" && !pathname.includes("/login")) {
        notificationToast(message);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const [onlyOnce, setOnlyOnce] = useState(true);
  useEffect(() => {
    if (onlyOnce) {
      gADataLayerPushMotability(theme.name, {
        originalLocation:
          document.location.protocol +
          "//" +
          document.location.hostname +
          document.location.pathname +
          document.location.search,
      });
    }
    setOnlyOnce(false);
  }, [onlyOnce, theme.name]);

  return checkJWT && !isLoggingOut ? (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgotten-username" element={<ForgottenUsername />} />
      <Route path="/forgotten-password" element={<ForgottenPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/login/vwfs" element={<LoginVWFS />} />
      <Route path="/404" element={<NotFound />} />
      <Route path="/" element={<PrivateRoute component={Home} />} />
      <Route
        path="/vehicle/:id"
        element={<PrivateRoute component={VehicleDetails} />}
      />
      <Route
        path="/vehicle/showroom/:id"
        element={<PrivateRoute component={ShowroomDetails} />}
      />
      <Route
        path="/vehicle/purchase/:id"
        element={<PrivateRoute component={Purchases} />}
      />
      <Route
        path="/vehicle/terms-and-conditions/:id"
        element={<PrivateRoute component={TermsAndConditions} />}
      />
      <Route
        path="/purchases/purchase-confirmation/:id"
        element={<PrivateRoute component={PurchaseConfirmation} />}
      />
      <Route path="/messages" element={<PrivateRoute component={Messages} />} />
      <Route
        path="/bids-and-requests"
        element={<PrivateRoute component={BidsAndRequests} />}
      />
      <Route
        path="/purchases/archive"
        element={<PrivateRoute component={PurchasesArchive} />}
      />
      <Route
        path="/shortlist"
        element={<PrivateRoute component={Shortlist} />}
      />
      <Route path="/invoices" element={<PrivateRoute component={Invoices} />} />
      <Route
        path="/activity-log"
        element={<PrivateRoute component={ActivityLog} />}
      />
      <Route
        path="/help-centre"
        element={<PrivateRoute component={HelpCentre} />}
      />
      <Route
        path="/notification-preference"
        element={<PrivateRoute component={NotificationPreference} />}
      />
      <Route
        path="/change-password"
        element={
          <PrivateRoute
            component={ChangePassword}
            strictUserType={USER_TYPE_MAP.Any}
          />
        }
      />
      <Route
        path="/terms-and-conditions-updates"
        element={<PrivateRoute component={TermsAndConditionsUpdates} />}
      />
      <Route
        path="/account-administration"
        element={<AdministratorRoute component={AccountAdministration} />}
      />
      <Route
        path="/product-support"
        element={<ProductSupportRoute component={ProductSupportHome} />}
      />
      <Route
        path="/product-support/dummy"
        element={<ProductSupportRoute component={ProductSupportDummy} />}
      />
      <Route
        path={vendorPaths.CLAIMS}
        element={<VendorRoute component={ClaimsHomePage} />}
      />
      <Route
        path={vendorPaths.NEW_CLAIMS}
        element={<VendorRoute component={NewClaims} />}
      />
      <Route
        path={vendorPaths.CLAIMS_IN_PROGRESS}
        element={<VendorRoute component={InProgressClaims} />}
      />
      <Route
        path={vendorPaths.CALLBACK_REQUESTED}
        element={<VendorRoute component={CallbackRequestedClaims} />}
      />
      <Route
        path={vendorPaths.OFFER_ACTION_REQUIRED}
        element={<VendorRoute component={OfferActionClaims} />}
      />
      <Route
        path={vendorPaths.COMPLETED_CLAIMS}
        element={<VendorRoute component={CompletedClaims} />}
      />
      <Route
        path="/vendor/claim/:id"
        element={<VendorRoute component={VendorClaimDetails} />}
      />
      <Route
        path={vendorPaths.CAMPAIGN_MANAGEMENT}
        element={<VendorRoute component={CampaignManagement} />}
      />
      <Route
        path={vendorPaths.CREATE_CAMPAIGNS}
        element={<VendorRoute component={CreateCampaign} />}
      />
      <Route
        path={vendorPaths.NEW_CAMPAIGNS}
        element={<VendorRoute component={Campaigns} />}
      />
      <Route
        path="/vendor/campaign-management/campaign/:id"
        element={<VendorRoute component={CreateCampaign} />}
      />
      <Route path="/claims" element={<PrivateRoute component={Claims} />} />
      <Route path="/claim/:id" element={<PrivateRoute component={Claim} />} />
      <Route
        path="/vendor/vehicle/:id"
        element={<VendorRoute component={VendorVehicleDetails} />}
      />
      <Route
        path="/vendor/auction-sale/:id"
        element={<VendorRoute component={VendorAuctionView} />}
      />
      <Route
        path="/vendor/modify-vehicle-details/:id"
        element={<VendorRoute component={ModifyVehicleDetails} />}
      />
      <Route
        path="/vendor/vehicle-search"
        element={<VendorRoute component={VehicleSearch} />}
      />
    </Routes>
  ) : (
    <LoadingTransition />
  );
};

export default RoutesContainer;
