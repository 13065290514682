import styled from "styled-components";

import SkeletonDefaultPageVendor from "./SkeletonDefaultPageVendor";
import breakpoints from "../../../theme/breakpoints";

const StyledSkeletonDefaultPageVendor = styled(SkeletonDefaultPageVendor) `
  .skeleton__page-heading {
    max-width: ${(props) => props.fullWidth ? "" : "119rem"};
    height: 11rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    gap: 0;
    padding: 0 ${(props) => props.fullWidth ? "15rem" : "0"};

    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      gap: 60rem;
      padding: ${(props) => props.theme.SPACING.LARGE} ${(props) => props.fullWidth ? "5rem" : "0"};
    }

    .skeleton__page-title {
      width: 50%;
      height: 5rem;
      display: block;
      background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[80]};
      ${(props) => props.theme.ANIMATIONS["shimmerDarker"]};

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        width: ${(props) => props.fullWidth ? "10%" : "20%"};
      }
    }
    .skeleton__page-user {
      width: 50%;
      height: 5rem;
      background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[80]};
      ${(props) => props.theme.ANIMATIONS["shimmerDarker"]};

      @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
        width: ${(props) => props.fullWidth ? "10%" : "20%"};
      }
    }
  }
  .skeleton__page-content {
    height: 100vh;
    background-color: ${(props) => props.theme.COLOURS.PRIMARY.shades[5]};
  }
`;

export default StyledSkeletonDefaultPageVendor;
