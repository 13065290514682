import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import VendorVehicleRecordReceivePaymentModal from "../../components/VendorVehicleRecordReceivePaymentModal";
import { setActiveConfirmModalOptions } from "../../../state/reducers/vendor-vehicle/genericActiveState";
import { vehicleMenuAction } from "../../../state/reducers/vendor-vehicle/vehicleDetails";

const VendorVehicleRecordReceivePaymentModalContainer = ({ className }) => {
  const dispatch = useDispatch();
  const vehicleReference = useSelector(
    (state) =>
      state.vendorVehicleRecord.genericActiveState.activeVehicleReference
  );
  const purchaseInformationData = useSelector(
    (state) => state.vendorVehicleRecord.purchaseInformation.data
  );
  const purchaseInformationFetching = useSelector(
    (state) => state.vendorVehicleRecord.purchaseInformation.isFetching
  );

  const onConfirmHandle = (paymentReference, paymentDate) => {
    const type = "vehicle";
    const data = {
      vehicleReference,
      action: "receive-payment",
    };
    const paymentData = {
      paymentReference,
      paymentDate,
    };

    dispatch(setActiveConfirmModalOptions(type, data));
    dispatch(
      vehicleMenuAction({
        actionType: data.action,
        vehicleId: data.vehicleReference,
        actionData: paymentData,
      })
    );
  };

  return (
    <VendorVehicleRecordReceivePaymentModal
      className={className}
      vehicleReference={vehicleReference}
      purchaseInformationData={purchaseInformationData}
      purchaseInformationFetching={purchaseInformationFetching}
      onConfirmHandle={onConfirmHandle}
    />
  );
};
export default VendorVehicleRecordReceivePaymentModalContainer;
