import {
  FetchVehicleRecordPrimaryDetails,
  FetchVehicleRecordMoreInfoDetails,
  FetchAdditionalEquipment,
  UpdateAdditionalEquipment,
  ModifyVehicleDetails,
  VehicleCancelPayment,
  VehicleRemoveFromSale,
  VehicleCancelReturn,
  VehicleCancelDefleet,
  AddVehicleWorkshop,
  RemoveVehicleWorkshop,
  FetchGenericColour,
  VehicleReceivePayment,
  UpdateAuctionGradeAndSite,
  FetchAuctionGradeSites,
  VehiclePublish,
  ReturnVehicle,
  DefleetVehicle,
} from "../../../api/vendor-vehicle/VehicleDetails";
import { genericFetchEventForNavigation } from "../vendorVehicleRecord";

import { openModal, closeModal } from "../global";
import { setActiveInspectionId } from "./inspections";
import { clearActiveConfirmModalOptions } from "./genericActiveState";

import dayjs from "dayjs";

export const FETCH_VEHICLE_PRIMARY_DETAILS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PRIMARY_DETAILS";
export const FETCH_VEHICLE_PRIMARY_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PRIMARY_DETAILS_SUCCESS";
export const FETCH_VEHICLE_PRIMARY_DETAILS_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_PRIMARY_DETAILS_ERROR";
export const FETCH_VEHICLE_MORE_INFO_DETAILS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_MORE_INFO_DETAILS";
export const FETCH_VEHICLE_MORE_INFO_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/FETCH_VEHICLE_MORE_INFO_DETAILS_SUCCESS";
export const FETCH_VEHICLE_MORE_INFO_DETAILS_ERROR =
  "@vehicleDetailsVendor/FETCH_VEHICLE_MORE_INFO_DETAILS_ERROR";
export const FETCH_ADDITIONAL_EQUIPMENT =
  "@vehicleDetailsVendor/FETCH_ADDITIONAL_EQUIPMENT";
export const FETCH_ADDITIONAL_EQUIPMENT_SUCCESS =
  "@vehicleDetailsVendor/FETCH_ADDITIONAL_EQUIPMENT_SUCCESS";
export const FETCH_ADDITIONAL_EQUIPMENT_ERROR =
  "@vehicleDetailsVendor/FETCH_ADDITIONAL_EQUIPMENT_ERROR";
export const UPDATE_ADDITIONAL_EQUIPMENT =
  "@vehicleDetailsVendor/UPDATE_ADDITIONAL_EQUIPMENT";
export const UPDATE_ADDITIONAL_EQUIPMENT_SUCCESS =
  "@vehicleDetailsVendor/UPDATE_ADDITIONAL_EQUIPMENT_SUCCESS";
export const UPDATE_ADDITIONAL_EQUIPMENT_ERROR =
  "@vehicleDetailsVendor/UPDATE_ADDITIONAL_EQUIPMENT_ERROR";
export const MODIFY_VEHICLE_DETAILS =
  "@vehicleDetailsVendor/MODIFY_VEHICLE_DETAILS";
export const MODIFY_VEHICLE_DETAILS_SUCCESS =
  "@vehicleDetailsVendor/MODIFY_VEHICLE_DETAILS_SUCCESS";
export const MODIFY_VEHICLE_DETAILS_ERROR =
  "@vehicleDetailsVendor/MODIFY_VEHICLE_DETAILS_ERROR";
export const CLEAR_VEHICLE_DATA = "@vehicleDetailsVendor/CLEAR_VEHICLE_DATA";

export const VEHICLE_MENU_ACTION = "@vehicleDetailsVendor/VEHICLE_MENU_ACTION";
export const VEHICLE_MENU_ACTION_SUCCESS =
  "@vehicleDetailsVendor/VEHICLE_MENU_ACTION_SUCCESS";
export const VEHICLE_MENU_ACTION_ERROR =
  "@vehicleDetailsVendor/VEHICLE_MENU_ACTION_ERROR";

export const UPDATE_FROM_VEHICLE_LOOKUP =
  "@vehicleDetailsVendor/UPDATE_FROM_VEHICLE_LOOKUP";
export const CLEAR_VEHICLE_LOOKUP_EDIT_FLAG =
  "@vehicleDetailsVendor/CLEAR_VEHICLE_LOOKUP_EDIT_FLAG";

export const FETCH_AUCTION_GRADE_SITES =
  "@vehicleDetailsVendor/FETCH_AUCTION_GRADE_SITES";
export const FETCH_AUCTION_GRADE_SITES_SUCCESS =
  "@vehicleDetailsVendor/FETCH_AUCTION_GRADE_SITES_SUCCESS";
export const FETCH_AUCTION_GRADE_SITES_ERROR =
  "@vehicleDetailsVendor/FETCH_AUCTION_GRADE_SITES_ERROR";

export const UPDATE_AUCTION_GRADE_AND_SITE =
  "@vehicleDetailsVendor/UPDATE_AUCTION_GRADE_AND_SITE";
export const UPDATE_AUCTION_GRADE_AND_SITE_SUCCESS =
  "@vehicleDetailsVendor/UPDATE_AUCTION_GRADE_AND_SITE_SUCCESS";
export const UPDATE_AUCTION_GRADE_AND_SITE_ERROR =
  "@vehicleDetailsVendor/UPDATE_AUCTION_GRADE_AND_SITE_ERROR";
export const REFRESH_GALLERY_VIEW =
  "@vehicleDetailsVendor/REFRESH_GALLERY_VIEW";

export const fetchVehiclePrimaryDetails =
  (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_PRIMARY_DETAILS });

    const vehicleDetailsInstance = new FetchVehicleRecordPrimaryDetails({
      params: {
        vehicleId: vehicleId,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        if (
          response.titleInfo.regNo === null ||
          response.titleInfo.regNo === ""
        ) {
          dispatch(openModal("generic-error"));
        } else {
          dispatch(fetchVehiclePrimaryDetailsSuccess(response));
          dispatch(
            setActiveInspectionId(
              response?.gradeOtherInfo?.mostRecentInspectionId
            )
          );
        }
      },
      (err) => {
        dispatch(fetchVehiclePrimaryDetailsError(err));
        dispatch(openModal("generic-error"));
      }
    );
  };

const fetchVehiclePrimaryDetailsSuccess = (data) => async (dispatch) => {
  await dispatch({
    type: FETCH_VEHICLE_PRIMARY_DETAILS_SUCCESS,
    payload: data,
  });
};

const fetchVehiclePrimaryDetailsError = (error) => {
  return {
    type: FETCH_VEHICLE_PRIMARY_DETAILS_ERROR,
    payload: error,
  };
};

export const fetchVehicleMoreInfoDetails =
  (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_MORE_INFO_DETAILS });

    const vehicleDetailsInstance = new FetchVehicleRecordMoreInfoDetails({
      params: {
        vehicleId: vehicleId,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(fetchVehicleMoreInfoDetailsSuccess(response));
      },
      (err) => {
        dispatch(fetchVehicleMoreInfoDetailsError(err));
      }
    );
  };

const fetchVehicleMoreInfoDetailsSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_MORE_INFO_DETAILS_SUCCESS,
    payload: data,
  };
};

const fetchVehicleMoreInfoDetailsError = (error) => {
  return {
    type: FETCH_VEHICLE_MORE_INFO_DETAILS_ERROR,
    payload: error,
  };
};

export const updateAdditionalEquipment =
  (vehicleId, equipmentText) => (dispatch, getState) => {
    dispatch({ type: UPDATE_ADDITIONAL_EQUIPMENT });

    const vehicleDetailsInstance = new UpdateAdditionalEquipment({
      params: {
        vehicleId: vehicleId,
        equipmentText: equipmentText,
      },
      credentials: getState().authentication.credentials,
    });
    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(updateAdditionalEquipmentSuccess(equipmentText));
      },
      (err) => {
        dispatch(updateAdditionalEquipmentError(err));
        dispatch(openModal("generic-error"));
      }
    );
  };
const updateAdditionalEquipmentSuccess = (data) => {
  return {
    type: UPDATE_ADDITIONAL_EQUIPMENT_SUCCESS,
    payload: data,
  };
};
const updateAdditionalEquipmentError = (error) => {
  return {
    type: UPDATE_ADDITIONAL_EQUIPMENT_ERROR,
    payload: error,
  };
};

export const clearVehicleData = () => {
  return {
    type: CLEAR_VEHICLE_DATA,
  };
};

export const fetchAdditionalEquipment = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_ADDITIONAL_EQUIPMENT });

  const vehicleDetailsInstance = new FetchAdditionalEquipment({
    params: {
      vehicleId: vehicleId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(fetchAdditionalEquipmentSuccess(response));
    },
    (err) => {
      dispatch(fetchAdditionalEquipmentError(err));
    }
  );
};

const fetchAdditionalEquipmentSuccess = (data) => {
  return {
    type: FETCH_ADDITIONAL_EQUIPMENT_SUCCESS,
    payload: data,
  };
};

const fetchAdditionalEquipmentError = (error) => {
  return {
    type: FETCH_ADDITIONAL_EQUIPMENT_ERROR,
    payload: error,
  };
};

export const vehicleMenuAction = (config) => (dispatch, getState) => {
  const { actionType, vehicleId, actionData } = config;

  dispatch({ type: VEHICLE_MENU_ACTION });
  dispatch(openModal("generic-loader-modal"));

  let apiInstance = getApiInstance({
    actionType,
    vehicleId,
    actionData,
    credentials: getState().authentication.credentials,
  });

  apiInstance?.call().then(
    (response) => {
      dispatch(vehicleMenuActionSuccess({ actionType, vehicleId, response }));
      dispatch(openModal("vendor-vehicle-record-success-modal"));
      setTimeout(() => {
        dispatch(closeModal());
        dispatch(clearActiveConfirmModalOptions());
      }, 1000);
    },
    (err) => {
      dispatch(vehicleMenuActionError(err));
      dispatch(clearActiveConfirmModalOptions());
      dispatch(openModal("generic-error"));
    }
  );
};

const vehicleMenuActionSuccess =
  ({ actionType, vehicleId, data }) =>
    (dispatch, getState) => {
      dispatch({
        type: VEHICLE_MENU_ACTION_SUCCESS,
        payload: data,
      });

      switch (actionType) {
        case "workshop":
        case "release-workshop":
        case "remove-from-sale":
        case "cancel-payment":
        case "cancel-return":
        case "cancel-defleet":
        case "get-generic-colour":
        case "receive-payment":
        case "publish":
        case "return-vehicle":
        case "terminate-vehicle":
        default:
          dispatch(genericFetchEventForNavigation(null, vehicleId));
          break;
      }
    };

const vehicleMenuActionError = (error) => (dispatch, getState) => {
  dispatch({
    type: VEHICLE_MENU_ACTION_ERROR,
    payload: error,
  });

  switch (error.actionType) {
    case "get-generic-colour":
      dispatch(openModal("vendor-vehicle-record-dvla-error-modal"));
      break;
    default:
      break;
  }
};

const getApiInstance = ({ actionType, vehicleId, actionData, credentials }) => {
  const commonParams = { vehicleId };
  let params;

  switch (actionType) {
    case "workshop":
      params = { ...commonParams, workshopReason: actionData };
      return new AddVehicleWorkshop({ params, credentials });
    case "release-workshop":
      return new RemoveVehicleWorkshop({ params: commonParams, credentials });
    case "remove-from-sale":
      return new VehicleRemoveFromSale({ params: commonParams, credentials });
    case "cancel-payment":
      return new VehicleCancelPayment({ params: commonParams, credentials });
    case "cancel-return":
      return new VehicleCancelReturn({ params: commonParams, credentials });
    case "cancel-defleet":
      return new VehicleCancelDefleet({ params: commonParams, credentials });
    case "get-generic-colour":
      return new FetchGenericColour({ params: commonParams, credentials });
    case "receive-payment":
      params = {
        ...commonParams,
        paymentRef: actionData.paymentReference,
        paymentReceivedDate: actionData.paymentDate,
      };
      return new VehicleReceivePayment({ params, credentials });
    case "publish":
      params = {
        ...commonParams,
        publishSessionType: actionData.publishSessionType,
        biddingEndDateTime: actionData.publishSessionType === "Timed" ? actionData.biddingEndDateTime : undefined,
        allowBuyNow: actionData.publishSessionType !== "FixedPrice" ? actionData.allowBuyNow : undefined,
      };
      return new VehiclePublish({ params, credentials });
    case "return-vehicle":
      params = { ...commonParams, returnDate: actionData };
      return new ReturnVehicle({ params, credentials });
    case "terminate-vehicle":
      return new DefleetVehicle({ params: commonParams, credentials });
    default:
      return null;
  }
};

const formatDate = (date) =>
  date && date !== "" ? dayjs(date).format("YYYY/MM/DD") : null;

export const modifyVehicleDetails =
  (
    vehicleId,
    keyVehicleData,
    contractualVehicleData,
    legalVehicleData,
    otherVehicleData,
    navigate
  ) =>
    (dispatch, getState) => {
      dispatch({ type: MODIFY_VEHICLE_DETAILS });
      dispatch(openModal("generic-loader-modal"));

      const params = {
        vehicleId,
        Vehicle: {
          base: {
            registration: keyVehicleData.editRegistration === getState().vendorVehicleRecord.vehicleDetails?.primaryDetails?.data?.titleInfo?.regNo ? null : keyVehicleData.editRegistration,
            capCode: keyVehicleData.editCapCode,
            capId: keyVehicleData.editCapId,
            vehicleType: keyVehicleData.editVehicleType,
            odometerDetail: {
              type: keyVehicleData.editOdometerType,
              reading: keyVehicleData.editOdometer,
              readingDate: formatDate(keyVehicleData.editOdometerReadingDate),
              readingConfirmed: keyVehicleData.editOdometerConfirmed,
            },
            vin: legalVehicleData.editVin,
            bodyColour: otherVehicleData.editExteriorColour,
            interiorColourTrim: otherVehicleData.editInteriorColour,
            numberOfSeats:
              otherVehicleData.editNumOfSeats > 0
                ? otherVehicleData.editNumOfSeats
                : null,
            modelYear: keyVehicleData.editModelYear,
            assetNumber: legalVehicleData.editAssetId,
            emissions: legalVehicleData.editEmissions,
            condition: otherVehicleData.editVehicleCondition,
            v5Available: legalVehicleData.editV5Status,
            v5DocumentReferenceNumber: legalVehicleData.editV5Reference,
            mot: legalVehicleData.editMotStatus,
            motExpiryDate: legalVehicleData.editMotExpiryDate,
            registrationDate: formatDate(keyVehicleData.editDateOfRegistration),
            cherishedPlate: keyVehicleData.editCherishedPlate,
          },
          additionalInfo: {
            make: keyVehicleData.editMake,
            model: keyVehicleData.editModel,
            derivative: keyVehicleData.editDerivative,
            bodyStyle: keyVehicleData.editBodyStyle,
            numberOfDoors:
              otherVehicleData.editNumOfDoors > 0
                ? otherVehicleData.editNumOfDoors
                : null,
            transmission: keyVehicleData.editTransmission,
            fuelType: keyVehicleData.editFuelType,
            engineSize: otherVehicleData.editEngineSize,
            serviceHistory: legalVehicleData.editServiceHistoryStatus,
            maxPower: otherVehicleData.editPower,
            maxTorque: otherVehicleData.editTorque,
            maxSpeed: otherVehicleData.editMaxSpeed,
            formerKeepers: legalVehicleData.editFormerKeepers === "" || legalVehicleData.editFormerKeepers === null ? 0 : legalVehicleData.editFormerKeepers,
            insuranceGroup: legalVehicleData.editInsuranceGroup,
            collectionOdometerDetail: {
              type: contractualVehicleData.editCollectionOdometerType,
              reading: contractualVehicleData.editCollectionOdometer,
              readingDate: formatDate(contractualVehicleData.editCollectionDate),
            },
            primaryColour: otherVehicleData.editGenericColour,
          },
        },
        VehicleStatus: {
          estimatedReturnDate: legalVehicleData.editEstimatedReturnDate,
          readyForDefleet: otherVehicleData.editReadyForDefleet,
        },
        Contract: {
          contractNumber: contractualVehicleData.editContractNumber,
          contractStartDate: formatDate(contractualVehicleData.editContractStart),
          contractEndDate: formatDate(contractualVehicleData.editContractEnd),
          contractTerm: contractualVehicleData.editContractTerm,
          contractDistance: contractualVehicleData.editContractDistance,
          leaseType: contractualVehicleData.editLeaseType,
        },
      };

      const vehicleDetailsInstance = new ModifyVehicleDetails({
        params,
        credentials: getState().authentication.credentials,
      });

      vehicleDetailsInstance.call().then(
        (response) => {
          dispatch(modifyVehicleDetailsSuccess(response));
          dispatch(openModal("vendor-vehicle-record-success-modal"));
          setTimeout(() => {
            dispatch(closeModal());
            dispatch(clearActiveConfirmModalOptions());
          }, 1000);
          navigate(`/vendor/vehicle/${vehicleId}`);
        },
        (err) => {
          dispatch(modifyVehicleDetailsError(err.response.data));
          dispatch(clearActiveConfirmModalOptions());
          dispatch(openModal("generic-error"));
        }
      );
    };

const modifyVehicleDetailsSuccess = (data) => ({
  type: MODIFY_VEHICLE_DETAILS_SUCCESS,
  payload: data,
});

const modifyVehicleDetailsError = (error) => ({
  type: MODIFY_VEHICLE_DETAILS_ERROR,
  payload: error,
});

export const updateFromVehicleLookup = (selectedVehicleData) => {
  return {
    type: UPDATE_FROM_VEHICLE_LOOKUP,
    payload: selectedVehicleData,
  };
};

export const clearVehicleLookupEditFlag = () => {
  return {
    type: CLEAR_VEHICLE_LOOKUP_EDIT_FLAG,
  };
};

export const fetchAuctionGradeSites =
  (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_AUCTION_GRADE_SITES });

    const vehicleDetailsInstance = new FetchAuctionGradeSites({
      params: {
        vehicleId: vehicleId,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(fetchAuctionGradeSitesSuccess(response));
      },
      (err) => {
        dispatch(fetchAuctionGradeSitesError(err));
      }
    );
  };

const fetchAuctionGradeSitesSuccess = (data) => {
  return {
    type: FETCH_AUCTION_GRADE_SITES_SUCCESS,
    payload: data,
  };
};

const fetchAuctionGradeSitesError = (error) => {
  return {
    type: FETCH_AUCTION_GRADE_SITES_ERROR,
    payload: error,
  };
};

export const updateAuctionGradeAndSite =
  (auctionGradeAndSiteData) => (dispatch, getState) => {
    dispatch({ type: UPDATE_AUCTION_GRADE_AND_SITE });

    const vehicleDetailsInstance = new UpdateAuctionGradeAndSite({
      params: {
        vehicleId: auctionGradeAndSiteData.vehicleId,
        auctionGrade: auctionGradeAndSiteData.auctionGrade ?? "",
        siteId: auctionGradeAndSiteData.siteId,
        siteType: auctionGradeAndSiteData.siteType ?? "",
        siteName: auctionGradeAndSiteData.siteName ?? "",
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(updateAuctionGradeAndSiteSuccess(response));
      },
      (err) => {
        dispatch(updateAuctionGradeAndSiteError(err));
      }
    );
  };

const updateAuctionGradeAndSiteSuccess = (data) => {
  return {
    type: UPDATE_AUCTION_GRADE_AND_SITE_SUCCESS,
    payload: data,
  };
};

const updateAuctionGradeAndSiteError = (error) => {
  return {
    type: UPDATE_AUCTION_GRADE_AND_SITE_ERROR,
    payload: error,
  };
};

export const refreshGalleryView = (data) => {
  return {
    type: REFRESH_GALLERY_VIEW,
    payload: data,
  };
}

const initialState = {
  primaryDetails: {
    data: null,
    isFetching: false,
    error: null,
  },
  moreInfoDetails: {
    data: null,
    isFetching: false,
    error: null,
  },
  additionalEquipment: {
    data: null,
    isFetching: false,
    isUpdating: false,
    error: null,
  },
  auctionGradeSiteData: {
    data: null,
    isFetching: false,
    isUpdating: false,
    error: null,
  },
  updateFromVehicleLookupFlag: false,
  isExecutingAction: false,
  modifyVehicleErrorDetails: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VEHICLE_PRIMARY_DETAILS:
      return {
        ...state,
        primaryDetails: {
          ...state.primaryDetails,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_PRIMARY_DETAILS_SUCCESS:
      return {
        ...state,
        primaryDetails: {
          ...state.primaryDetails,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_PRIMARY_DETAILS_ERROR:
      return {
        ...state,
        primaryDetails: {
          ...state.primaryDetails,
          isFetching: false,
          error: action.payload,
        },
      };
    case FETCH_VEHICLE_MORE_INFO_DETAILS:
      return {
        ...state,
        moreInfoDetails: {
          ...state.moreInfoDetails,
          isFetching: true,
        },
      };
    case FETCH_VEHICLE_MORE_INFO_DETAILS_SUCCESS:
      return {
        ...state,
        moreInfoDetails: {
          ...state.moreInfoDetails,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_VEHICLE_MORE_INFO_DETAILS_ERROR:
      return {
        ...state,
        moreInfoDetails: {
          ...state.moreInfoDetails,
          error: action.payload,
          isFetching: false,
        },
      };
    case FETCH_ADDITIONAL_EQUIPMENT:
      return {
        ...state,
        additionalEquipment: {
          ...state.additionalEquipment,
          isFetching: true,
        },
      };
    case FETCH_ADDITIONAL_EQUIPMENT_SUCCESS:
      return {
        ...state,
        additionalEquipment: {
          ...state.additionalEquipment,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_ADDITIONAL_EQUIPMENT_ERROR:
      return {
        ...state,
        additionalEquipment: {
          ...state.additionalEquipment,
          isFetching: false,
          error: action.payload,
        },
      };
    case UPDATE_ADDITIONAL_EQUIPMENT:
      return {
        ...state,
        additionalEquipment: {
          ...state.additionalEquipment,
          isUpdating: true,
        },
      };
    case UPDATE_ADDITIONAL_EQUIPMENT_SUCCESS:
      return {
        ...state,
        additionalEquipment: {
          ...state.additionalEquipment,
          data: {
            ...state.additionalEquipment.data,
            equipmentText: action.payload,
          },
          isUpdating: false,
        },
      };
    case UPDATE_ADDITIONAL_EQUIPMENT_ERROR:
      return {
        ...state,
        additionalEquipment: {
          ...state.additionalEquipment,
          isUpdating: false,
          error: action.payload,
        },
      };
    case VEHICLE_MENU_ACTION:
      return {
        ...state,
        isExecutingAction: true,
      };
    case VEHICLE_MENU_ACTION_SUCCESS:
      return {
        ...state,
        isExecutingAction: false,
      };
    case VEHICLE_MENU_ACTION_ERROR:
      return {
        ...state,
        isExecutingAction: false,
      };
    case CLEAR_VEHICLE_DATA:
      return {
        ...state,
        ...initialState,
      };
    case MODIFY_VEHICLE_DETAILS:
      return {
        ...state,
        modifyVehicleErrorDetails: null,
      };
    case MODIFY_VEHICLE_DETAILS_SUCCESS:
      return {
        ...state,
        modifyVehicleErrorDetails: null,
      };
    case MODIFY_VEHICLE_DETAILS_ERROR:
      return {
        ...state,
        modifyVehicleErrorDetails: action.payload,
      };
    case UPDATE_FROM_VEHICLE_LOOKUP:
      return {
        ...state,
        updateFromVehicleLookup: true,
        primaryDetails: {
          ...state.primaryDetails,
          data: {
            ...state.primaryDetails.data,
            keyInfo: {
              ...state.primaryDetails.data.keyInfo,
              capCode: action.payload.capCode,
              capId: action.payload.capId,
              bodyStyle: action.payload.bodyStyle,
              transmissionType: action.payload.transmissionType,
              fuelType: action.payload.fuelType,
            },
            titleInfo: {
              ...state.primaryDetails.data.titleInfo,
              make: action.payload.make,
              model: action.payload.model,
              derivative: action.payload.derivative,
            },
          },
        },
      };
    case CLEAR_VEHICLE_LOOKUP_EDIT_FLAG:
      return {
        ...state,
        updateFromVehicleLookup: false,
      };

    case FETCH_AUCTION_GRADE_SITES:
      return {
        ...state,
        auctionGradeSiteData: {
          ...state.auctionGradeSiteData,
          isFetching: true,
        },
      };
    case FETCH_AUCTION_GRADE_SITES_SUCCESS:
      return {
        ...state,
        auctionGradeSiteData: {
          ...state.auctionGradeSiteData,
          data: action.payload,
          isFetching: false,
        },
      };
    case FETCH_AUCTION_GRADE_SITES_ERROR:
      return {
        ...state,
        auctionGradeSiteData: {
          ...state.auctionGradeSiteData,
          isFetching: false,
          error: action.payload,
        },
      };
    case UPDATE_AUCTION_GRADE_AND_SITE:
      return {
        ...state,
        auctionGradeSiteData: {
          ...state.auctionGradeSiteData,
          isUpdating: true,
        },
      };
    case UPDATE_AUCTION_GRADE_AND_SITE_SUCCESS:
      return {
        ...state,
        auctionGradeSiteData: {
          ...state.auctionGradeSiteData,
          isUpdating: false,
        },
      };
    case UPDATE_AUCTION_GRADE_AND_SITE_ERROR:
      return {
        ...state,
        auctionGradeSiteData: {
          ...state.auctionGradeSiteData,
          isUpdating: false,
          error: action.payload,
        },
      };
    case REFRESH_GALLERY_VIEW:
      return {
        ...state,
        primaryDetails: {
          ...state.primaryDetails,
          data: {
            ...state.primaryDetails.data,
            galleryInfo: action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
