import {
  FetchVehicleRecordProfiles,
  IncludeInOnlineSelling,
  AllocateToProfile,
  RemoveFromProfile,
  IncludeInAutoProfiling,
} from "../../../api/vendor-vehicle/Profiling";

import { openModal, closeModal } from "../global";
import { VENDOR_VEHICLE_NAVIGATION_OPTIONS } from "./../../../shared/utility";
import { genericFetchEventForNavigation } from "../vendorVehicleRecord";
import { clearActiveConfirmModalOptions } from "./genericActiveState";

export const FETCH_VEHICLE_RECORD_PROFILES =
  "@profiles/FETCH_VEHICLE_RECORD_PROFILES";
export const FETCH_VEHICLE_RECORD_PROFILES_SUCCESS =
  "@profiles/FETCH_VEHICLE_RECORD_PROFILES_SUCCESS";
export const FETCH_VEHICLE_RECORD_PROFILES_ERROR =
  "@profiles/FETCH_VEHICLE_RECORD_PROFILES_ERROR";

export const INCLUDE_IN_ONLINE_SELLING = "@profiles/INCLUDE_IN_ONLINE_SELLING";
export const INCLUDE_IN_ONLINE_SELLING_SUCCESS =
  "@profiles/INCLUDE_IN_ONLINE_SELLING_SUCCESS";
export const INCLUDE_IN_ONLINE_SELLING_ERROR =
  "@profiles/INCLUDE_IN_ONLINE_SELLING_ERROR";

export const ALLOCATE_TO_PROFILE = "@profiles/ALLOCATE_TO_PROFILE";
export const ALLOCATE_TO_PROFILE_SUCCESS =
  "@profiles/ALLOCATE_TO_PROFILE_SUCCESS";
export const ALLOCATE_TO_PROFILE_ERROR = "@profiles/ALLOCATE_TO_PROFILE_ERROR";

export const REMOVE_FROM_PROFILE = "@profiles/REMOVE_FROM_PROFILE";
export const REMOVE_FROM_PROFILE_SUCCESS =
  "@profiles/REMOVE_FROM_PROFILE_SUCCESS";
export const REMOVE_FROM_PROFILE_ERROR = "@profiles/REMOVE_FROM_PROFILE_ERROR";

export const INCLUDE_IN_AUTO_PROFILING = "@profiles/INCLUDE_IN_AUTO_PROFILING";
export const INCLUDE_IN_AUTO_PROFILING_SUCCESS =
  "@profiles/INCLUDE_IN_AUTO_PROFILING_SUCCESS";
export const INCLUDE_IN_AUTO_PROFILING_ERROR =
  "@profiles/INCLUDE_IN_AUTO_PROFILING_ERROR";

export const fetchVehicleRecordProfiles =
  (vehicleId) => (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_RECORD_PROFILES });

    const vehicleDetailsInstance = new FetchVehicleRecordProfiles({
      params: {
        vehicleId: vehicleId,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(fetchVehicleRecordProfilesSuccess(response));
      },
      (err) => {
        dispatch(fetchVehicleRecordProfilesError(err));
      }
    );
  };

const fetchVehicleRecordProfilesSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_RECORD_PROFILES_SUCCESS,
    payload: data,
  };
};

const fetchVehicleRecordProfilesError = (error) => {
  return {
    type: FETCH_VEHICLE_RECORD_PROFILES_ERROR,
    payload: error,
  };
};

export const includeInOnlineSelling = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: INCLUDE_IN_ONLINE_SELLING });

  const vehicleDetailsInstance = new IncludeInOnlineSelling({
    params: {
      vehicleId: vehicleId,
    },
    credentials: getState().authentication.credentials,
  });

  vehicleDetailsInstance.call().then(
    (response) => {
      dispatch(includeInOnlineSellingSuccess(response));
      dispatch(
        genericFetchEventForNavigation(
          VENDOR_VEHICLE_NAVIGATION_OPTIONS.PROFILES,
          vehicleId
        )
      );
    },
    (err) => {
      dispatch(includeInOnlineSellingError(err));
      return dispatch(openModal("generic-error"));
    }
  );
};

const includeInOnlineSellingSuccess = (data) => {
  return {
    type: INCLUDE_IN_ONLINE_SELLING_SUCCESS,
    payload: data,
  };
};

const includeInOnlineSellingError = (error) => {
  return {
    type: INCLUDE_IN_ONLINE_SELLING_ERROR,
    payload: error,
  };
};

export const allocateToProfile =
  (vehicleId, profileValue) => (dispatch, getState) => {
    dispatch({ type: ALLOCATE_TO_PROFILE });

    const vehicleDetailsInstance = new AllocateToProfile({
      params: {
        vehicleId: vehicleId,
        profileId: profileValue,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(allocateToProfileSuccess(response));
        dispatch(
          genericFetchEventForNavigation(
            VENDOR_VEHICLE_NAVIGATION_OPTIONS.PROFILES,
            vehicleId
          )
        );
      },
      (err) => {
        dispatch(allocateToProfileError(err));
        return dispatch(openModal("generic-error"));
      }
    );
  };

const allocateToProfileSuccess = (data) => {
  return {
    type: ALLOCATE_TO_PROFILE_SUCCESS,
    payload: data,
  };
};

const allocateToProfileError = (error) => {
  return {
    type: ALLOCATE_TO_PROFILE_ERROR,
    payload: error,
  };
};

export const includeInAutoProfiling =
  (vehicleId, autoProfile, profileId) => (dispatch, getState) => {
    dispatch({ type: INCLUDE_IN_AUTO_PROFILING });
    dispatch(openModal("generic-loader-modal"));

    const vehicleDetailsInstance = new IncludeInAutoProfiling({
      params: {
        vehicleId: vehicleId,
        autoProfile: autoProfile,
        profileId: profileId,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(includeInAutoProfilingSuccess(response));
        dispatch(openModal("vendor-vehicle-record-success-modal"));
        setTimeout(() => {
          dispatch(closeModal());
          dispatch(clearActiveConfirmModalOptions());
        }, 1000);
        dispatch(
          genericFetchEventForNavigation(
            VENDOR_VEHICLE_NAVIGATION_OPTIONS.PROFILES,
            vehicleId
          )
        );
      },
      (err) => {
        dispatch(includeInAutoProfilingError(err));
        return dispatch(openModal("generic-error"));
      }
    );
  };

const includeInAutoProfilingSuccess = (data) => {
  return {
    type: INCLUDE_IN_AUTO_PROFILING_SUCCESS,
    payload: data,
  };
};

const includeInAutoProfilingError = (error) => {
  return {
    type: INCLUDE_IN_AUTO_PROFILING_ERROR,
    payload: error,
  };
};

export const removeFromProfile =
  (vehicleId, profileId) => (dispatch, getState) => {
    dispatch({ type: REMOVE_FROM_PROFILE });

    const vehicleDetailsInstance = new RemoveFromProfile({
      params: {
        vehicleId: vehicleId,
        profileId: profileId,
      },
      credentials: getState().authentication.credentials,
    });

    vehicleDetailsInstance.call().then(
      (response) => {
        dispatch(removeFromProfileSuccess(response));
        dispatch(
          genericFetchEventForNavigation(
            VENDOR_VEHICLE_NAVIGATION_OPTIONS.PROFILES,
            vehicleId
          )
        );
      },
      (err) => {
        dispatch(removeFromProfileError(err));
        return dispatch(openModal("generic-error"));
      }
    );
  };

const removeFromProfileSuccess = (data) => {
  return {
    type: REMOVE_FROM_PROFILE_SUCCESS,
    payload: data,
  };
};

const removeFromProfileError = (error) => {
  return {
    type: REMOVE_FROM_PROFILE_ERROR,
    payload: error,
  };
};

const initialState = {
  profiles: null,
  vendorProfiles: null,
  autoProfile: null,
  daysOnSale: null,
  buyNowPrice: null,
  isFetching: false,
  error: null,
  autoProfiling: {
    isFetchingAutoProfiling: false,
    error: false,
    data: null,
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INCLUDE_IN_ONLINE_SELLING:
      return {
        ...state,
        isFetching: true,
      };
    case INCLUDE_IN_ONLINE_SELLING_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case INCLUDE_IN_ONLINE_SELLING_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case FETCH_VEHICLE_RECORD_PROFILES:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_VEHICLE_RECORD_PROFILES_SUCCESS:
      return {
        ...state,
        profiles: action.payload?.profiles,
        vendorProfiles: action.payload?.vendorProfiles,
        autoProfile: action.payload?.autoProfile,
        daysOnSale: action.payload?.daysOnSale,
        buyNowPrice: action.payload?.buyNowPrice,
        isFetching: false,
      };
    case FETCH_VEHICLE_RECORD_PROFILES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    case ALLOCATE_TO_PROFILE:
      return {
        ...state,
        autoProfiling: {
          isFetchingAutoProfiling: true,
        },
      };
    case ALLOCATE_TO_PROFILE_SUCCESS:
      return {
        ...state,
        autoProfiling: {
          isFetchingAutoProfiling: false,
          data: action.payload,
        },
      };
    case ALLOCATE_TO_PROFILE_ERROR:
      return {
        ...state,
        autoProfiling: {
          isFetchingAutoProfiling: false,
          error: action.payload,
        },
      };
    case INCLUDE_IN_AUTO_PROFILING:
      return {
        ...state,
        autoProfiling: {
          isFetchingAutoProfiling: true,
        },
      };
    case INCLUDE_IN_AUTO_PROFILING_SUCCESS:
      return {
        ...state,
        autoProfiling: {
          isFetchingAutoProfiling: false,
          data: action.payload,
        },
      };
    case INCLUDE_IN_AUTO_PROFILING_ERROR:
      return {
        ...state,
        autoProfiling: {
          isFetchingAutoProfiling: false,
          error: action.payload,
        },
      };

    case REMOVE_FROM_PROFILE:
      return {
        ...state,
        isFetching: true,
      };
    case REMOVE_FROM_PROFILE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case REMOVE_FROM_PROFILE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
