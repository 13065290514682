import styled from "styled-components";
import VendorVehicleRecordReturnVehicleModal from "./VendorVehicleRecordReturnVehicleModal";

const StyledVendorVehicleRecordReturnVehicleModal = styled(VendorVehicleRecordReturnVehicleModal)`
    width: 55rem;

    .return-modal-content {
        margin: ${(props) => props.theme.SPACING.REGULAR};
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .return-modal-header {
        margin-top: ${(props) => props.theme.SPACING.SMALL};
        margin-bottom: ${(props) => props.theme.SPACING.SMALL};
    }

    .row {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 ${(props) => props.theme.SPACING.SMALL} 0;
        
        & > * {
            flex: 1;
        }
    }

    .detail-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .detail-list__row {
            width: 100%;
            display: flex;
            text-align: left;
            justify-content: space-evenly;
            margin: 0 0 ${(props) => props.theme.SPACING.SMALL} 0;

            p {
                width: 100%;
                margin: 0;
                span {
                    color: ${(props) => props.theme.COLOURS.PRIMARY.shades[60]};
                }
            }
            p:nth-of-type(2) {
                color: ${(props) => props.theme.COLOURS.PRIMARY.base};
            }
        }
    }
`;

export default StyledVendorVehicleRecordReturnVehicleModal;
