import styled from "styled-components";
import GenericInformation from "./GenericInformation.jsx";
import breakpoints from "../../../views/theme/breakpoints.js";
import Tooltip from "../Tooltip";
import ParagraphText from "../ParagraphText";
 
const StyledGenericInformation = styled(GenericInformation)`
 
.title-label {
  font-weight: bold;
  color: ${(props) => props.theme.COLOURS.ACCENT.base};
 
}
 
.info-options-containers, .moreInfo-containers {
  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    display: flex;
    gap: 6rem;
  }
}
.info-options-containers-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
 
  .info-options-row, .generic-info-item {
    width: 100%;
    display: flex;
    margin: 0 0 ${(props) => props.theme.SPACING.SMALL} 0;
 
    p {
      width: 100%;
      margin: 0;
      span {
        color: ${(props) => props.theme.COLOURS.PRIMARY.shades[60]};
      }
    }
    p:nth-of-type(2) {
      color: ${(props) => props.theme.COLOURS.PRIMARY.base};
    }
 
    button {
      width: 100%;
      margin: 0;
      text-align: left;
    }
  }
}

.generic-info-icon {
  padding-top: 2px;
  cursor: pointer;
  margin-left: 55%;
}
 
.info-options-row .info-returnedValue {
  width: 100%;
}
 
.info-options-row .info-returnedValue.with-icon {
  width: 18%;
}
 
.info-Information-text {
  padding: 0 1.5rem;
}
 
.info-Text-row {
  display: grid;
  grid-template-columns: auto;
  margin-top: 0.5rem;
 
    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      grid-template-columns: 24% 76%;
  }
 
  .info-text {
    overflow-wrap: anywhere;
  }
}
 
.info-returnedValue, .info-text {
  color:  ${(props) => props.theme.COLOURS.PRIMARY.base};
}
 
${Tooltip} {
${ParagraphText} {
  width: max-content
}    
  }
 
`;
 
export default StyledGenericInformation;