import { FetchVendorPermissions, FetchSiteInformation } from "../../api/VendorInformation";
import { openModal, closeModal } from "./global";

export const FETCH_VENDOR_PERMISSIONS = 
  "@vendorInformation/FETCH_VENDOR_PERMISSIONS";
export const FETCH_VENDOR_PERMISSIONS_SUCCESS = 
  "@vendorInformation/FETCH_VENDOR_PERMISSIONS_SUCCESS";
export const FETCH_VENDOR_PERMISSIONS_FAILURE = 
  "@vendorInformation/FETCH_VENDOR_PERMISSIONS_FAILURE";

export const FETCH_SITE_INFORMATION = "@vehicleDetailsVendor/FETCH_SITE_INFORMATION";
export const FETCH_SITE_INFORMATION_SUCCESS = "@vehicleDetailsVendor/FETCH_SITE_INFORMATION_SUCCESS";
export const FETCH_SITE_INFORMATION_ERROR = "@vehicleDetailsVendor/FETCH_SITE_INFORMATION_ERROR";

export const fetchVendorPermissions = () => (dispatch, getState) => {
    dispatch({
        type: FETCH_VENDOR_PERMISSIONS,
    });
    const vendorInfoInstance = new FetchVendorPermissions({
        credentials: getState().authentication.credentials,
    });

    return vendorInfoInstance.call().then(
        (response) => {
            return dispatch(fetchVendorPermissionsSuccess(response));
        },
    (err) => {
        return dispatch(fetchVendorPermissionsFailure(err));
        },
    );
}

const fetchVendorPermissionsSuccess = (data) => {
    return {
        type: FETCH_VENDOR_PERMISSIONS_SUCCESS,
        payload: data,
    };
};

const fetchVendorPermissionsFailure = (error) => {
    return {
        type: FETCH_VENDOR_PERMISSIONS_FAILURE,
        payload: error,
    };
};

export const fetchSiteInformation = (siteType, siteId) => (dispatch, getState) => {
    dispatch({ type: FETCH_SITE_INFORMATION });
    dispatch(openModal("generic-loader-modal"));
  
    const siteInformationInstance = new FetchSiteInformation({
      params: {
        siteType: siteType,
        siteId: siteId,
      },
      credentials: getState().authentication.credentials,
    })
  
    siteInformationInstance.call().then(
      (response) => {
        if (
          response === null
        ) {
          dispatch(openModal("generic-error"));
        } else {
          const processedAddress = response?.address?.split(/\r?\n/);
          response.addressLines = processedAddress;
          dispatch(fetchSiteInformationSuccess(response));
          dispatch(openModal("vendor-site-information-modal"));
        }
      },
      (err) => {
        dispatch(fetchSiteInformationError(err));
        dispatch(openModal("generic-error"));
      }
    );
  }
  
  const fetchSiteInformationSuccess = (data) => async (dispatch) => {
    await dispatch({
      type: FETCH_SITE_INFORMATION_SUCCESS,
      payload: data,
    });
  };
  
  const fetchSiteInformationError = (error) => {
    return {
      type: FETCH_SITE_INFORMATION_ERROR,
      payload: error,
    };
  };

const initialState = {
    isFetchingVendorPermissions: false,
    vendorPermissions: {},
    siteDetails: {
        data: null,
        isFetching: false,
        error: null,
      },
  };

export const reducer = (state = initialState, action) => {
 switch (action.type) {
    case FETCH_VENDOR_PERMISSIONS:
        return {
            ...state,
            isFetchingVendorPermissions: true,
        };
    case FETCH_VENDOR_PERMISSIONS_SUCCESS:
        return {
            ...state,
            isFetchingVendorPermissions: false,
            vendorPermissions: action.payload,
        };
    case FETCH_VENDOR_PERMISSIONS_FAILURE:
        return {
            ...state,
            isFetchingVendorPermissions: false,
        };
    case FETCH_SITE_INFORMATION:
        return {
          ...state,
          siteDetails: {
            ...state.siteDetails,
            isFetching: true,
          },
        };
    case FETCH_SITE_INFORMATION_SUCCESS:
        return {
          ...state,
          siteDetails: {
            ...state.siteDetails,
            data: action.payload,
            isFetching: false,
          },
        };
    case FETCH_SITE_INFORMATION_ERROR:
        return {
          ...state,
          siteDetails: {
            ...state.siteDetails,
            isFetching: false,
            error: action.payload,
          },
        };
    default:
        return state;
    }
};

export default reducer;