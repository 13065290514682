import React, { useContext, useEffect, useState } from "react";
import Icon from "../../../views/components/Icon";
import HeaderText from "../../../views/components/HeaderText";
import { ThemeContext } from "styled-components";
import DragDrop from "../../../views/components/DragDrop";
import InputError from "../../../views/components/BidInput/child-components/BidInputError";
import { Background } from "react-imgix";
import { isValidUrl } from "../../../shared/utility";
import * as consts from "./const";

const VendorVehicleImagesModal = ({
  className,
  images = [],
  setImages,
  vehicleReferenceId,
  onSaveVehicleImage,
  onDeleteVehicleImage,
  onSetDefaultImage,
  onSetImageOrder,
  imageDataFetching,
  imageDataSaving,
}) => {
  const theme = useContext(ThemeContext);

  const SHOW_EMPTY_PLACEHOLDERS = true;
  const [displayedImages, setDisplayedImages] = useState([]);

  useEffect(() => {
    setDisplayedImages([...images]);
    setUploadProgress([]);
  }, [images]);

  const maxFileSize = 1048576 * 10; // 10MB max file size
  const maxNumberOfImages = 32;
  const acceptedFileTypes = { "image/jpeg": [".jpg", ".jpeg"] };

  const [validFileType, setValidFileType] = useState(true);
  const [validFileSize, setValidFileSize] = useState(true);
  const [validNumberOfFiles, setValidNumberOfFiles] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadProgress, setUploadProgress] = useState([]);

  const handleBlockChange = (fileList) => {
    if (
      fileList.length > 0 &&
      Array.isArray(fileList) &&
      validFileSize &&
      validFileType &&
      validNumberOfFiles
    ) {
      const fileDataArray = fileList.map((file) => {
        const fileData = {
          vehicleId: `${vehicleReferenceId}`,
          fileName: file.name,
          fileSize: file.size,
          file: file,
          id: file.name,
        };

        // Add the file to the uploadProgress state
        setUploadProgress((prevProgress) => [
          ...prevProgress,
          { id: fileData.id, progress: 0 },
        ]);

        return fileData;
      });

      onSaveVehicleImage(fileDataArray);
    }
  };

  const onDropRejected = (fileRejections) => {
    fileRejections.forEach((x) =>
      x.errors.forEach((err) => {
        if (err.code === "file-invalid-type") {
          setValidFileType(false);
          setErrorMessage(consts.ERRORS.INVALID_FILE_TYPE);
          return;
        }
        if (err.code === "file-too-large") {
          setValidFileSize(false);
          setErrorMessage(consts.ERRORS.FILE_TOO_LARGE);
          return;
        }
        if (err.code === "too-many-files") {
          setValidNumberOfFiles(false);
          setErrorMessage(consts.ERRORS.TOO_MANY_FILES);
          return;
        }
      })
    );
    setTimeout(() => {
      setValidFileType(true);
      setValidFileSize(true);
      setValidNumberOfFiles(true);
      setErrorMessage("");
    }, 5000);
  };

  useEffect(() => {
    if (SHOW_EMPTY_PLACEHOLDERS) {
      setDisplayedImages((prevImages) => {
        const placeholdersNeeded = uploadProgress.length + 1; // One for each item in uploadProgress plus one for DragDrop
        const totalImages = images.length + placeholdersNeeded;
        const newImages = [...images];

        for (
          let i = images.length;
          i < totalImages && i < maxNumberOfImages;
          i++
        ) {
          newImages.push({
            id: `placeholder-${i}`,
            isUploadProgress: i < images.length + uploadProgress.length,
          });
        }
        return newImages;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadProgress, images, maxNumberOfImages]);

  const handleDrop = (e, dropIndex) => {
    const dragIndex = parseInt(e.dataTransfer.getData("imageIndex"), 10);
    if (dragIndex === dropIndex) return;

    const newImages = [...images];
    const [draggedImage] = newImages.splice(dragIndex, 1);
    newImages.splice(dropIndex, 0, draggedImage);
    setImages(newImages);
    onSetImageOrder(newImages);
  };

  const handleDragStart = (e, index) => {
    const dragImage = document.createElement("div");
    dragImage.style.width = "100px";
    dragImage.style.height = "100px";
    dragImage.style.backgroundImage = `url(${images[index]})`;
    dragImage.style.backgroundSize = "cover";
    dragImage.style.backgroundPosition = "center";
    dragImage.style.border = "1px solid #ccc";
    dragImage.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.3)";
    document.body.appendChild(dragImage);
    e.dataTransfer.setDragImage(dragImage, 50, 50);
    setTimeout(() => document.body.removeChild(dragImage), 0);
    e.dataTransfer.setData("imageIndex", index);
  };

  const handleDragEnter = (e, index) => {
    e.preventDefault();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div className={className}>
      <HeaderText>
        {consts.MANAGE_MARKETING_IMAGES}({images.length} {consts.OF}{" "}
        {maxNumberOfImages} {consts.UPLOADED})
      </HeaderText>
      <div className="image-container">
        {!imageDataFetching &&
          displayedImages &&
          displayedImages.map((image, index) => (
            <div
              key={image ? `${image}-${index}` : `placeholder-${index}`}
              className="media-thumbs"
              style={{
                border:
                  image && isValidUrl(image) && index === 0
                    ? `5px solid ${theme.COLOURS.ACCENT.base}`
                    : image
                      ? "none"
                      : "1px dashed #ccc",
              }}
              draggable={!!image}
              onDragStart={!!image ? (e) => handleDragStart(e, index) : null}
              onDrop={!!image ? (e) => handleDrop(e, index) : null}
              onDragOver={!!image ? handleDragOver : null}
              onDragEnter={!!image ? (e) => handleDragEnter(e, index) : null}
            >
              {image && isValidUrl(image) ? (
                <>
                  <div onDoubleClick={() => window.open(image, "_blank")}>
                    <Background
                      key={index}
                      src={image}
                      className="uploaded-image"
                      imgixParams={{
                        fit: "clamp",
                      }}
                    />
                  </div>
                  <div
                    className="image-action-delete"
                    onClick={() => onDeleteVehicleImage(index)}
                  >
                    <Icon
                      type="trash"
                      width="24"
                      height="24"
                      strokeColour={theme.COLOURS.RED.base}
                    />
                  </div>
                </>
              ) : image && image.isUploadProgress ? (
                <>
                  {uploadProgress.length > 0 &&
                    uploadProgress.map((item) => (
                      <div className="image-placeholder" key={item.id}>
                        <div className="rotation">
                          <Icon type="loading" width="62" height="54" />
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <div className="image-placeholder">
                  {index >= images.length && (
                    <DragDrop
                      onDrop={handleBlockChange}
                      onDropRejected={onDropRejected}
                      acceptedTypes={acceptedFileTypes}
                      maxSize={maxFileSize}
                      maxFiles={maxNumberOfImages - images.length}
                      primaryParagraphText="Drag and drop images here"
                      secondaryParagraphText="or click to browse"
                      allowMultiple
                      noMinHeight
                      noBorder
                      disabled={imageDataSaving}
                    />
                  )}
                </div>
              )}
            </div>
          ))}
      </div>
      {(!validFileType || !validFileSize || !validNumberOfFiles) && (
        <div className="error-container">
          <InputError errorMessage={errorMessage} />
        </div>
      )}
    </div>
  );
};

export default VendorVehicleImagesModal;
