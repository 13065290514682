import React from "react";

const MapMultiLineText = ({text}) => {
    if (!text) {
      return "-";
    }
    return text.map((item, index) => (
      <React.Fragment key={index}>
        {item}
        <br />
      </React.Fragment>
    ));
  };

  export default MapMultiLineText;
