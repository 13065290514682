/* eslint-disable */
import React, { lazy, Suspense, useContext } from "react";
import SkeletonDefaultPage from "../components/Skeletons/SkeletonDefaultPage";
import SkeletonHomePage from "../components/Skeletons/SkeletonHomePage";
import SkeletonVehicleDetailsPage from "../components/Skeletons/SkeletonVehicleDetailsPage";
import SkeletonDefaultPageVendor from "../components/Skeletons/SkeletonDefaultPageVendor";
import SkeletonShowroomPage from "../components/Skeletons/SkeletonShowroomPage";
import SkeletonDefaultPageCampaignManagement from "../components/Skeletons/SkeletonDefaultPageCampaignManagement";
import { ThemeContext } from "styled-components";

const LazyHome = lazy(() => import("./Home"));
const LazyLogin = lazy(() => import("./Login"));
const LazyNotFound = lazy(() => import("./NotFound"));
const LazyForgottenUsername = lazy(() => import("./ForgottenUsername"));
const LazyForgottenPassword = lazy(() => import("./ForgottenPassword"));
const LazyLoginVWFS = lazy(() => import("./LoginVWFS"));
const LazyVehicleDetails = lazy(() => import("./VehicleDetails"));
const LazyShowroomDetails = lazy(() => import("./ShowroomDetails"));
const LazyPurchases = lazy(() => import("./Purchases"));
const LazyPurchaseConfirmation = lazy(() => import("./PurchaseConfirmation"));
const LazyMessages = lazy(() => import("./Messages"));
const LazyTermsAndConditions = lazy(() => import("./TermsAndConditions"));
const LazyTermsAndConditionsUpdates = lazy(() =>
  import("./TermsAndConditionsUpdates")
);
const LazyBidsAndRequests = lazy(() => import("./BidsAndRequests"));
const LazyPurchasesArchive = lazy(() => import("./PurchasesArchive"));
const LazyShortlist = lazy(() => import("./Shortlist"));
const LazyInvoices = lazy(() => import("./Invoices"));
const LazyActivityLog = lazy(() => import("./ActivityLog"));
const LazyAccountAdministration = lazy(() => import("./AccountAdministration"));
const LazyChangePassword = lazy(() => import("./ChangePassword"));
const LazyResetPassword = lazy(() => import("./ResetPassword"));
const LazyHelpCentre = lazy(() => import("./HelpCentre"));
const LazyNotificationPreference = lazy(() =>
  import("./NotificationPreference")
);
const LazyProductSupportHome = lazy(() => import("./ProductSupportHome"));
const LazyProductSupportDummy = lazy(() =>
  import("./ProductSupportHome/ProductSupportDummy")
);

const LazyClaims = lazy(() => import("./Claims"));
const LazyClaim = lazy(() => import("./Claim"));
const LazyClaimsHomePage = lazy(() =>
  import("../../vendor/pages/ClaimsHomePage")
);
const LazyNewClaims = lazy(() => import("../../vendor/pages/NewClaims"));
const LazyInProgressClaims = lazy(() =>
  import("../../vendor/pages/InProgressClaims")
);
const LazyCallbackRequestedClaims = lazy(() =>
  import("../../vendor/pages/CallbackRequestedClaims")
);
const LazyOfferActionClaims = lazy(() =>
  import("../../vendor/pages/OfferActionClaims")
);
const LazyVendorClaimDetails = lazy(() =>
  import("./../../vendor/pages/ClaimDetails")
);
const LazyVendorVehicleDetails = lazy(() =>
  import("./../../vendor/pages/VehicleDetails")
);

const LazyVendorAuctionView = lazy(() =>
  import("./../../vendor/pages/VendorAuctionView")
);

const LazyModifyVehicleDetails = lazy(() =>
  import("./../../vendor/pages/ModifyVehicleDetails")
);

const LazyVehicleSearch = lazy(() =>
  import("./../../vendor/pages/VehicleSearch")
);

const LazyCompletedClaims = lazy(() =>
  import("./../../vendor/pages/CompletedClaims")
);

const LazyCampaignManagement = lazy(() =>
  import("./../../vendor/pages/CampaignManagement")
);
const LazyCreateCampaign = lazy(() =>
  import("./../../vendor/pages/CreateCampaign")
);

const LoginFallback = () => {
  const theme = useContext(ThemeContext);
  return (
    <div style={{ backgroundColor: `${theme.COLOURS.SECONDARY.shades[10]}` }} />
  );
};

const PurchaseFallback = () => {
  const theme = useContext(ThemeContext);
  return (
    <div>
      <div
        style={{
          height: "15rem",
          backgroundColor: `${theme.COLOURS.PRIMARY.shades[10]}`,
        }}
      />
      <div
        style={{
          height: "100vh",
          backgroundColor: `${theme.COLOURS.PRIMARY.shades[5]}`,
        }}
      />
    </div>
  );
};

const DefaultPageFallback = <SkeletonDefaultPage />;
const DefaultPageFallbackVendor = <SkeletonDefaultPageVendor hasNav={false} />;
const DefaultPageFallbackVendorFullWidth = <SkeletonDefaultPageVendor hasNav={false} fullWidth={true} />
const DefaultPageFallbackNoNav = <SkeletonDefaultPage hasNav={false} />;
const HomePageFallback = <SkeletonHomePage />;
const VehicleDetailsPageFallback = <SkeletonVehicleDetailsPage />;
const ShowroomFallBack = <SkeletonShowroomPage />;
const DefaultFallbackCampaignManagement = (
  <SkeletonDefaultPageCampaignManagement hasNav={false} />
);

const Home = (props) => (
  <Suspense fallback={HomePageFallback}>
    <LazyHome {...props} />
  </Suspense>
);

const CampaignManagement = (props) => (
  <Suspense fallback={DefaultFallbackCampaignManagement}>
    <LazyCampaignManagement {...props} />
  </Suspense>
);

const CreateCampaign = (props) => (
  <Suspense fallback={DefaultFallbackCampaignManagement}>
    <LazyCreateCampaign {...props} />
  </Suspense>
);

const Login = (props) => (
  <Suspense fallback={<LoginFallback />}>
    <LazyLogin {...props} />
  </Suspense>
);

const NotFound = (props) => (
  <Suspense fallback={<LoginFallback />}>
    <LazyNotFound {...props} />
  </Suspense>
);

const ForgottenUsername = (props) => (
  <Suspense fallback={<LoginFallback />}>
    <LazyForgottenUsername {...props} />
  </Suspense>
);

const ForgottenPassword = (props) => (
  <Suspense fallback={<LoginFallback />}>
    <LazyForgottenPassword {...props} />
  </Suspense>
);

const LoginVWFS = (props) => (
  <Suspense fallback={<LoginFallback />}>
    <LazyLoginVWFS {...props} />
  </Suspense>
);

const VehicleDetails = (props) => (
  <Suspense fallback={VehicleDetailsPageFallback}>
    <LazyVehicleDetails {...props} />
  </Suspense>
);

const ShowroomDetails = (props) => (
  <Suspense fallback={ShowroomFallBack}>
    <LazyShowroomDetails {...props} />
  </Suspense>
);

const Purchases = (props) => (
  <Suspense fallback={<PurchaseFallback />}>
    <LazyPurchases {...props} />
  </Suspense>
);

const PurchaseConfirmation = (props) => (
  <Suspense fallback={<PurchaseFallback />}>
    <LazyPurchaseConfirmation {...props} />
  </Suspense>
);

const Messages = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyMessages {...props} />
  </Suspense>
);

const TermsAndConditions = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyTermsAndConditions {...props} />
  </Suspense>
);

const TermsAndConditionsUpdates = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyTermsAndConditionsUpdates {...props} />
  </Suspense>
);

const BidsAndRequests = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyBidsAndRequests {...props} />
  </Suspense>
);

const PurchasesArchive = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyPurchasesArchive {...props} />
  </Suspense>
);

const Shortlist = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyShortlist {...props} />
  </Suspense>
);

const Invoices = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyInvoices {...props} />
  </Suspense>
);

const ActivityLog = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyActivityLog {...props} />
  </Suspense>
);

const AccountAdministration = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyAccountAdministration {...props} />
  </Suspense>
);

const ChangePassword = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyChangePassword {...props} />
  </Suspense>
);

const ResetPassword = (props) => (
  <Suspense fallback={DefaultPageFallbackNoNav}>
    <LazyResetPassword {...props} />
  </Suspense>
);

const VendorAuctionView = (props) => (
  <Suspense fallback={DefaultPageFallbackVendorFullWidth}>
    <LazyVendorAuctionView {...props} />
  </Suspense>
);

const ModifyVehicleDetails = (props) => (
  <Suspense fallback={DefaultPageFallbackVendorFullWidth}>
    <LazyModifyVehicleDetails {...props} />
  </Suspense>
);

const VehicleSearch = (props) => (
  <Suspense fallback={DefaultPageFallbackVendor}>
    <LazyVehicleSearch {...props} />
  </Suspense>
);

const HelpCentre = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyHelpCentre {...props} />
  </Suspense>
);

const NotificationPreference = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyNotificationPreference {...props} />
  </Suspense>
);

const ProductSupportHome = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyProductSupportHome {...props} />
  </Suspense>
);

const ProductSupportDummy = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyProductSupportDummy {...props} />
  </Suspense>
);

const Claims = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyClaims {...props} />
  </Suspense>
);

const Claim = (props) => (
  <Suspense fallback={DefaultPageFallback}>
    <LazyClaim {...props} />
  </Suspense>
);

const ClaimsHomePage = (props) => (
  <Suspense fallback={DefaultPageFallbackVendor}>
    <LazyClaimsHomePage {...props} />
  </Suspense>
);

const NewClaims = (props) => (
  <Suspense fallback={DefaultPageFallbackVendor}>
    <LazyNewClaims {...props} />
  </Suspense>
);

const InProgressClaims = (props) => (
  <Suspense fallback={DefaultPageFallbackVendor}>
    <LazyInProgressClaims {...props} />
  </Suspense>
);

const CallbackRequestedClaims = (props) => (
  <Suspense fallback={DefaultPageFallbackVendor}>
    <LazyCallbackRequestedClaims {...props} />
  </Suspense>
);

const OfferActionClaims = (props) => (
  <Suspense fallback={DefaultPageFallbackVendor}>
    <LazyOfferActionClaims {...props} />
  </Suspense>
);

const VendorClaimDetails = (props) => (
  <Suspense fallback={DefaultPageFallbackVendor}>
    <LazyVendorClaimDetails {...props} />
  </Suspense>
);

const VendorVehicleDetails = (props) => (
  <Suspense fallback={DefaultPageFallbackVendorFullWidth}>
    <LazyVendorVehicleDetails {...props} />
  </Suspense>
);

const VendorVehicleInformation = (props) => (
  <Suspense fallback={DefaultPageFallbackVendor}>
    <LazyVendorVehicleInformation {...props} />
  </Suspense>
);

const VendorProfiles = (props) => (
  <Suspense fallback={DefaultPageFallbackVendor}>
    <LazyVendorProfiles {...props} />
  </Suspense>
);

const CompletedClaims = (props) => (
  <Suspense fallback={DefaultPageFallbackVendor}>
    <LazyCompletedClaims {...props} />
  </Suspense>
);

export {
  Home,
  Login,
  ForgottenUsername,
  ForgottenPassword,
  LoginVWFS,
  VehicleDetails,
  ShowroomDetails,
  Purchases,
  PurchaseConfirmation,
  Messages,
  TermsAndConditions,
  TermsAndConditionsUpdates,
  BidsAndRequests,
  PurchasesArchive,
  Shortlist,
  Invoices,
  ActivityLog,
  AccountAdministration,
  ChangePassword,
  ResetPassword,
  NotFound,
  HelpCentre,
  NotificationPreference,
  ProductSupportHome,
  ProductSupportDummy,
  Claims,
  Claim,
  ClaimsHomePage,
  NewClaims,
  InProgressClaims,
  CallbackRequestedClaims,
  OfferActionClaims,
  VendorClaimDetails,
  VendorVehicleDetails,
  VendorAuctionView,
  ModifyVehicleDetails,
  VehicleSearch,
  VendorVehicleInformation,
  VendorProfiles,
  CompletedClaims,
  CampaignManagement,
  CreateCampaign,
};
