export const headers = {
    returnVehicle: "Return Vehicle",
    returnDate: "Return Date",
};

export const errors = {
    validDate: 'Please enter a valid date',
};

export const actions = {
    confirm: 'Confirm',
    close: 'Close',
};
