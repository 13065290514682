import { updateObject } from "../../shared/utility";
import { FetchUserCustomisation, UpdateUserCustomisation, RemoveUserCustomisation } from "../../api/UserCustomisation";

export const FETCH_CUSTOMISATION = "@userCustomisations/FETCH_CUSTOMISATION";
export const FETCH_CUSTOMISATION_SUCCESS = "@userCustomisations/FETCH_CUSTOMISATION_SUCCESS";
export const FETCH_CUSTOMISATION_FAILURE = "@userCustomisations/FETCH_CUSTOMISATION_FAILURE";
export const SAVE_CUSTOMISATION = "@userCustomisations/SAVE_CUSTOMISATION";
export const SAVE_CUSTOMISATION_SUCCESS = "@userCustomisations/SAVE_CUSTOMISATION_SUCCESS";
export const SAVE_CUSTOMISATION_FAILURE = "@userCustomisations/SAVE_CUSTOMISATION_FAILURE";
export const REMOVE_CUSTOMISATION = "@userCustomisations/REMOVE_CUSTOMISATION";
export const REMOVE_CUSTOMISATION_SUCCESS = "@userCustomisations/REMOVE_CUSTOMISATION_SUCCESS";
export const REMOVE_CUSTOMISATION_FAILURE = "@userCustomisations/REMOVE_CUSTOMISATION_FAILURE";

const customisationTypeMap = {
    searchInputOrder: "VendorVehicleSearchInputOrder",
};

export const fetchCustomisation = (customisationType) => (dispatch, getState) => {
    dispatch({
        type: FETCH_CUSTOMISATION,
    });

    const fetchUserCustomisation = new FetchUserCustomisation({
        credentials: getState().authentication.credentials,
        params: { customisationType: customisationTypeMap[customisationType] },
    });

    return fetchUserCustomisation.call().then(
        (response) => {
            const data = response ? JSON.parse(response) : initialState[customisationType];
            dispatch(fetchCustomisationSuccess(data, customisationType));
        },
        (err) => {
            dispatch(fetchCustomisationFailure(err));
        },
    );
};

const fetchCustomisationSuccess = (data, customisationType) => {
    return {
        type: FETCH_CUSTOMISATION_SUCCESS,
        payload: data,
        customisationType,
    };
};

const fetchCustomisationFailure = (error) => {
    return {
        type: FETCH_CUSTOMISATION_FAILURE,
        payload: error,
    };
};

export const saveCustomisation = (customisationType, data) => (dispatch, getState) => {
    dispatch({
        type: SAVE_CUSTOMISATION,
    });

    const updateUserCustomisation = new UpdateUserCustomisation({
        credentials: getState().authentication.credentials,
        params: { customisationType: customisationTypeMap[customisationType], json: JSON.stringify(data) },
    });

    return updateUserCustomisation.call().then(
        (response) => {
            dispatch(saveCustomisationSuccess(response.data));
            dispatch(fetchCustomisation(customisationType));
        },
        (err) => {
            dispatch(saveCustomisationFailure(customisationType, err));
        },
    );
};

const saveCustomisationSuccess = (data) => {
    return {
        type: SAVE_CUSTOMISATION_SUCCESS,
        payload: data,
    };
};

const saveCustomisationFailure = (error) => {
    return {
        type: SAVE_CUSTOMISATION_FAILURE,
        payload: error,
    };
};

export const removeCustomisation = (customisationType) => (dispatch, getState) => {
    dispatch({
        type: REMOVE_CUSTOMISATION,
    });

    const removeUserCustomisation = new RemoveUserCustomisation({
        credentials: getState().authentication.credentials,
        params: { customisationType: customisationTypeMap[customisationType] },
    });

    return removeUserCustomisation.call().then(
        (response) => {
            dispatch(removeCustomisationSuccess(response.data));
            dispatch(fetchCustomisation(customisationType));
        },
        (err) => {
            dispatch(removeCustomisationFailure(customisationType, err));
        },
    );
};

const removeCustomisationSuccess = (data) => {
    return {
        type: REMOVE_CUSTOMISATION_SUCCESS,
        payload: data,
    };
};

const removeCustomisationFailure = (error) => {
    return {
        type: REMOVE_CUSTOMISATION_FAILURE,
        payload: error,
    };
};


const initialState = {
    isFetchingUserCustomisations: false,
    isSavingUserCustomisations: false,
    searchInputOrder: [
        "regNo",
        "vehicleId",
        "vin",
        "assetId",
        "currentStatus",
        "vehicleType",
        "source",
        "driverName",
        "contractNumber",
        "capId",
        "capCode",
        // "estReturnDateFrom",
        // "estReturnDateTo",
        "make",
        "model",
        "transmission",
        "fuelType",
        // "age",
        // "salesAccounts",
    ],
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CUSTOMISATION:
            return updateObject(state, { isFetchingUserCustomisations: true });
        case FETCH_CUSTOMISATION_SUCCESS:
            return updateObject(state, {
                isFetchingUserCustomisations: false,
                [action.customisationType]: action.payload,
            });
        case FETCH_CUSTOMISATION_FAILURE:
            return updateObject(state, {
                isFetchingUserCustomisations: false,
            });
        case SAVE_CUSTOMISATION:
            return updateObject(state, {
                isSavingUserCustomisations: true,
            });
        case SAVE_CUSTOMISATION_SUCCESS:
            return updateObject(state, {
                isSavingUserCustomisations: false,
            });
        case SAVE_CUSTOMISATION_FAILURE:
            return updateObject(state, {
                isSavingUserCustomisations: false,
            });
        case REMOVE_CUSTOMISATION:
            return updateObject(state, {
                isSavingUserCustomisations: true,
            });
        case REMOVE_CUSTOMISATION_SUCCESS:
            return updateObject(state, {
                isSavingUserCustomisations: false,
            });
        case REMOVE_CUSTOMISATION_FAILURE:
            return updateObject(state, {
                isSavingUserCustomisations: false,
            });
        default:
            return state;
    }
};

export default reducer;