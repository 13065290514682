import {
    GetVehicleImages,
    AddVehicleImage,
    RemoveVehicleImage,
    SetDefaultVehicleImage,
    SetVehicleImageOrder,
} from "../../../api/vendor-vehicle/VehicleDetails";
import { openModal, closeModal } from "./../global";
import { clearActiveConfirmModalOptions } from "./genericActiveState";
import { fetchVehiclePrimaryDetails, refreshGalleryView } from "./vehicleDetails";

export const GET_VEHICLE_IMAGES = "@vehicle/GET_VEHICLE_IMAGES";
export const GET_VEHICLE_IMAGES_SUCCESS =
    "@vehicle/GET_VEHICLE_IMAGES_SUCCESS";
export const GET_VEHICLE_IMAGES_ERROR =
    "@vehicle/GET_VEHICLE_IMAGES_ERROR";
export const SAVE_VEHICLE_IMAGE = "@vehicle/SAVE_VEHICLE_IMAGE";
export const SAVE_VEHICLE_IMAGE_SUCCESS =
    "@vehicle/SAVE_VEHICLE_IMAGE_SUCCESS";
export const SAVE_VEHICLE_IMAGE_ERROR =
    "@vehicle/SAVE_VEHICLE_IMAGE_ERROR";
export const REMOVE_VEHICLE_IMAGE = "@vehicle/REMOVE_VEHICLE_IMAGE";
export const REMOVE_VEHICLE_IMAGE_SUCCESS =
    "@vehicle/REMOVE_VEHICLE_IMAGE_SUCCESS";
export const REMOVE_VEHICLE_IMAGE_ERROR =
    "@vehicle/REMOVE_VEHICLE_IMAGE_ERROR";
export const REMOVE_ALL_VEHICLE_IMAGES = "@vehicle/REMOVE_ALL_VEHICLE_IMAGES";
export const REMOVE_ALL_VEHICLE_IMAGES_SUCCESS =
    "@vehicle/REMOVE_ALL_VEHICLE_IMAGES_SUCCESS";
export const REMOVE_ALL_VEHICLE_IMAGES_ERROR =
    "@vehicle/REMOVE_ALL_VEHICLE_IMAGES_ERROR";
export const SET_DEFAULT_VEHICLE_IMAGE = "@vehicle/SET_DEFAULT_VEHICLE_IMAGE";
export const SET_DEFAULT_VEHICLE_IMAGE_SUCCESS =
    "@vehicle/SET_DEFAULT_VEHICLE_IMAGE_SUCCESS";
export const SET_DEFAULT_VEHICLE_IMAGE_ERROR =
    "@vehicle/SET_DEFAULT_VEHICLE_IMAGE_ERROR";
export const SET_VEHICLE_IMAGE_ORDER = "@vehicle/SET_VEHICLE_IMAGE_ORDER";
export const SET_VEHICLE_IMAGE_ORDER_SUCCESS =
    "@vehicle/SET_VEHICLE_IMAGE_ORDER_SUCCESS";
export const SET_VEHICLE_IMAGE_ORDER_ERROR =
    "@vehicle/SET_VEHICLE_IMAGE_ORDER_ERROR";

export const getVehicleImages = (vehicleId, refreshGallery) => (dispatch, getState) => {
    dispatch({ type: GET_VEHICLE_IMAGES });

    const imagesInstance = new GetVehicleImages({
        params: {
            vehicleId: vehicleId,
        },
        credentials: getState().authentication.credentials,
    });

    return imagesInstance.call().then(
        (response) => {
            if (refreshGallery) {
                dispatch(refreshGalleryView(response.data));
            }
            else {
                dispatch(openModal("vendor-vehicle-images-modal"));
            }            
            dispatch(getVehicleImagesSuccess(response.data));            
            return response;
        },
        (err) => {
            dispatch(getVehicleImagesError(err));
            dispatch(openModal("generic-error"));
        }
    );
};

export const getVehicleImagesSuccess = (data) => {
    return {
        type: GET_VEHICLE_IMAGES_SUCCESS,
        payload: {
            data,
        },
    };
};

export const getVehicleImagesError = (error) => {
    return {
        type: GET_VEHICLE_IMAGES_ERROR,
        payload: {
            error,
        },
    };
};

export const saveVehicleImage = (imageData) => (dispatch, getState) => {
    dispatch({ type: SAVE_VEHICLE_IMAGE });
    const promises = imageData.map((data) => {
        const formData = new FormData();
        formData.append("vehicleId", data.vehicleId);
        formData.append("fileName", data.fileName);
        formData.append("overwrite", false);

        if (data.file && data.file.path) {
            formData.append("file", data.file);
        }

        const imagesInstance = new AddVehicleImage({
            params: formData,
            credentials: getState().authentication.credentials,
        });

        return imagesInstance.call();
    });
    return Promise.all(promises).then(
        (responses) => {
            dispatch(saveVehicleImageSuccess(responses));
            dispatch(getVehicleImages(imageData[0].vehicleId));
            return responses;
        },
        (err) => {
            dispatch(removeAllVehicleImagesError(err));
            dispatch(openModal("generic-error"));
        }
    );
};

export const saveVehicleImageSuccess = (data) => {
    return {
        type: SAVE_VEHICLE_IMAGE_SUCCESS,
        payload: {
            data,
        },
    };
};

export const saveVehicleImageError = (error) => {
    return {
        type: SAVE_VEHICLE_IMAGE_ERROR,
        payload: {
            error,
        },
    };
};

export const removeVehicleImage = (imageData) => (dispatch, getState) => {
    dispatch({ type: REMOVE_VEHICLE_IMAGE });

    const imagesInstance = new RemoveVehicleImage({
        params: {
            vehicleId: imageData.vehicleId,
            blobIds: [imageData.blobId]
        },
        credentials: getState().authentication.credentials,
    });

    return imagesInstance.call().then(
        (response) => {
            dispatch(removeVehicleImageSuccess(response));
            return response;
        },
        (err) => {
            dispatch(removeVehicleImageError(err));
            dispatch(openModal("generic-error"));
        }
    );
};

export const removeVehicleImageSuccess = (data) => {
    return {
        type: REMOVE_VEHICLE_IMAGE_SUCCESS,
        payload: {
            data,
        },
    };
};

export const removeVehicleImageError = (error) => {
    return {
        type: REMOVE_VEHICLE_IMAGE_ERROR,
        payload: {
            error,
        },
    };
};

export const removeAllVehicleImages = (imageData) => (dispatch, getState) => {
    dispatch({ type: REMOVE_ALL_VEHICLE_IMAGES });
    dispatch(openModal("generic-loader-modal"));

    const imagesInstance = new RemoveVehicleImage({
        params: {
            vehicleId: imageData.vehicleId,
            blobIds: imageData.blobIds
        },
        credentials: getState().authentication.credentials,
    });

    return imagesInstance.call().then(
        (response) => {
            dispatch(removeAllVehicleImagesSuccess(response));
            dispatch(getVehicleImages(imageData.vehicleId));
            dispatch(fetchVehiclePrimaryDetails(imageData.vehicleId));
            dispatch(openModal("vendor-vehicle-record-success-modal"));
            setTimeout(() => {
                dispatch(openModal("vendor-vehicle-images-modal"));
                dispatch(clearActiveConfirmModalOptions());
            }, 1000);
            return response;
        },
        (err) => {
            dispatch(removeAllVehicleImagesError(err));
            dispatch(openModal("generic-error"));
        }
    );
};

export const removeAllVehicleImagesSuccess = (data) => {
    return {
        type: REMOVE_ALL_VEHICLE_IMAGES_SUCCESS,
        payload: {
            data,
        },
    };
};

export const removeAllVehicleImagesError = (error) => {
    return {
        type: REMOVE_ALL_VEHICLE_IMAGES_ERROR,
        payload: {
            error,
        },
    };
};

export const setDefaultVehicleImage = (imageData) => (dispatch, getState) => {
    dispatch({ type: SET_DEFAULT_VEHICLE_IMAGE });

    const imagesInstance = new SetDefaultVehicleImage({
        params: {
            vehicleId: imageData.vehicleId,
            defaultBlobId: imageData.blobId
        },
        credentials: getState().authentication.credentials,
    });

    return imagesInstance.call().then(
        (response) => {
            dispatch(setDefaultVehicleImageSuccess(response));
            // dispatch(getVehicleImages(imageData.vehicleId));
            return response;
        },
        (err) => {
            dispatch(setDefaultVehicleImageError(err));
            dispatch(openModal("generic-error"));
        }
    );
};

export const setDefaultVehicleImageSuccess = (data) => {
    return {
        type: SET_DEFAULT_VEHICLE_IMAGE_SUCCESS,
        payload: {
            data,
        },
    };
};

export const setDefaultVehicleImageError = (error) => {
    return {
        type: SET_DEFAULT_VEHICLE_IMAGE_ERROR,
        payload: {
            error,
        },
    };
};

export const setImageOrder = (imageData) => (dispatch, getState) => {
    dispatch({ type: SET_VEHICLE_IMAGE_ORDER });

    const imagesInstance = new SetVehicleImageOrder({
        params: {
            vehicleId: imageData.vehicleId,
            imageOrderDetail: imageData.imageOrderDetail
        },
        credentials: getState().authentication.credentials,
    });

    return imagesInstance.call().then(
        (response) => {
            dispatch(setImageOrderSuccess(response));
            dispatch(getVehicleImages(imageData.vehicleId, true));
            dispatch(closeModal());
            return response;
        },
        (err) => {
            dispatch(setImageOrderError(err));
            dispatch(openModal("generic-error"));
        }
    );
};

export const setImageOrderSuccess = (data) => {
    return {
        type: SET_VEHICLE_IMAGE_ORDER_SUCCESS,
        payload: {
            data,
        },
    };
};

export const setImageOrderError = (error) => {
    return {
        type: SET_VEHICLE_IMAGE_ORDER_ERROR,
        payload: {
            error,
        },
    };
};

const initialState = {
    data: null,
    isSaving: false,
    isFetching: false,
    error: null,
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_VEHICLE_IMAGE:
            return {
                ...state,
                isSaving: true,
            };
        case SAVE_VEHICLE_IMAGE_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                isSaving: false,
            };
        case SAVE_VEHICLE_IMAGE_ERROR:
            return {
                ...state,
                isSaving: false,
                error: action.payload.error,
            };
        case GET_VEHICLE_IMAGES:
            return {
                ...state,
                isFetching: true,
            };
        case GET_VEHICLE_IMAGES_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                isFetching: false,
            };
        case GET_VEHICLE_IMAGES_ERROR:
            return {
                ...state,
                isFetching: false,
                error: action.payload.error,
            };
        case REMOVE_VEHICLE_IMAGE:
            return {
                ...state,
                isUpdating: true,
            };
        case REMOVE_VEHICLE_IMAGE_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                isUpdating: false,
            };
        case REMOVE_VEHICLE_IMAGE_ERROR:
            return {
                ...state,
                isUpdating: false,
                error: action.payload.error,
            };
        case REMOVE_ALL_VEHICLE_IMAGES:
            return {
                ...state,
                isUpdating: true,
            };
        case REMOVE_ALL_VEHICLE_IMAGES_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                isUpdating: false,
            };
        case REMOVE_ALL_VEHICLE_IMAGES_ERROR:
            return {
                ...state,
                isUpdating: false,
                error: action.payload.error,
            };
        case SET_DEFAULT_VEHICLE_IMAGE:
            return {
                ...state,
                isUpdating: true,
            };
        case SET_DEFAULT_VEHICLE_IMAGE_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                isUpdating: false,
            };
        case SET_DEFAULT_VEHICLE_IMAGE_ERROR:
            return {
                ...state,
                isUpdating: false,
                error: action.payload.error,
            };
        case SET_VEHICLE_IMAGE_ORDER:
            return {
                ...state,
                isUpdating: true,
            };
        case SET_VEHICLE_IMAGE_ORDER_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                isUpdating: false,
            };
        case SET_VEHICLE_IMAGE_ORDER_ERROR:
            return {
                ...state,
                isUpdating: false,
                error: action.payload.error,
            };
        default:
            return state;
    }
};

export default reducer;