import styled, { css } from "styled-components";
import InputText from "./InputText";

const StyledInputText = styled(InputText)`
  background-color: ${props => props.theme.COLOURS.WHITE};
  border-radius: 0.5rem;
  border: 0.2rem solid
    ${props =>
    props.validValue
      ? props.theme.COLOURS.PRIMARY.shades[20]
      : props.theme.COLOURS.RED.base};
  ${props =>
    props.withoutBorder &&
    css`
      border-style: none;
      outline: none;
    `};
  box-sizing: border-box;
  color: ${props => props.theme.COLOURS.PRIMARY.base};
  font-size: ${props => props.fontSize ? props.fontSize : props.theme.FONTS.sizes[60]};
  height: 5.2rem;
  ${props =>
    props.small &&
    css`
      font-size: ${props => props.theme.FONTS.sizes[40]};
      height: 3.2rem;
    `};
  padding: 0 ${props => props.theme.SPACING.REGULAR};
  ${props =>
    props.medium &&
    css`
      font-size: ${props => props.theme.FONTS.sizes[50]};
      height: 4.9rem;
      padding: 1rem;
    `};
  width: 100%;

  /* Chrome, Safari, Edge, Opera */
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type=number] {
    -moz-appearance: textfield;
  }
`;

export default StyledInputText;
